const mediaQuery = (state = Config.MEDIA_QUERIES[0], action) => {
    switch (action.type) {
        case 'MEDIA_QUERY_UPDATE':
            return action.mq || state;
        default:
            return state;
    }
};

export { mediaQuery };
