// PACKAGES
import React, { Component } from 'react';
// UI
import { Button } from '@beatport-group/ui/Buttons';
// UTILS
import { captureException } from 'integrations/Sentry';
import __ from 'localization';
import { history } from 'store';

const ERROR_MESSAGES = {
    400: __('Sorry, we cannot process that request right now.'),
    403: __("Sorry, you're not authorized to view this page."),
    404: __('Sorry, we could not find the page you wanted.'),
    500: __('Something has gone terribly wrong.'),
    default: __("That's an error."),
};

export const RenderError = ({ error, backButton, logError }) => {
    if (logError) {
        captureException(error);
    }

    return (
        <div className="error-boundary container--padded container--flex-columns container--full-size">
            <span className="error-boundary__status">{error.status}</span>
            <span className="error-boundary__message">{error.message}</span>
            <p>{ERROR_MESSAGES[error.status] ? ERROR_MESSAGES[error.status] : ERROR_MESSAGES.default}</p>
            {error.status > 500 || !error.status ? (
                <div className="error-boundary__contact">
                    {__('This error has been logged and we are looking into it.')}<br />
                    {__('Need help? Contact')} <a href="mailto:product@beatport.com">{__('product support')}</a>.
                </div>
            ) : null}
            {backButton && (
                <Button
                    onClick={() => history.goBack()}
                    className="error-boundary__button"
                    category="primary"
                >
                    {__('Take Me Back')}
                </Button>
            )}
            {Config.STACKTRACE_ENABLED && (
                <pre className="error-boundary__stack-trace">
                    <code>{error.stack}</code>
                </pre>
            )}
        </div>
    );
};
RenderError.defaultProps = {
    /**
   * @typedef {Error} error the error that will be displayed
   */
    error: Error(),
    /**
   * @typedef {Boolean} backButton should the component display a back button
   */
    backButton: false,
    /**
   * @typedef {Boolean} logError should the component log the error to Sentry
   */
    logError: false,
};

export default class ErrorBoundary extends Component {
    static defaultProps = {
        onError: () => { },
    }

    constructor (props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            info: null,
        };

        this.throwError = this.throwError.bind(this);
    }

    componentDidCatch (error, info) {
        captureException(error, info);
        this.throwError(error, info);
    }

    componentWillUnmount () {
        this.unmounted = true;
    }

    throwError (error, info = null) {
        this.props.onError(error);
        !this.unmounted && this.setState({
            hasError: true,
            error: error,
            info: info,
        });
    }

    render () {
        const { error, hasError } = this.state;

        if (hasError) {
            return <RenderError error={error} />;
        }

        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                throwError: this.throwError,
            });
        });
    }
}

export const withErrorBoundary = (WrappedComponent) => {
    return (props) => {
        return (
            <ErrorBoundary>
                <WrappedComponent {...props} />
            </ErrorBoundary>
        );
    };
};
