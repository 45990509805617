/**
 * Integration with Sentry SDK.
 *
 * See https://docs.sentry.io/clients/javascript/
 *
 * The main configuration for the sdk client happens in entry/sentry.js.
 */
import * as Sentry from '@sentry/browser';

/**
 * Decorator function that checks to see if Sentry
 * are available and enabled before invoking.
 *
 * @param {function} function to wrap
 *
 * @returns {function} the wrapped fnction
 */
export const shouldUseSentry = (f) => (...args) => {
    return Config.SENTRY_ENABLED
        ? f(...args)
        : null;
};

export const captureException = shouldUseSentry((error, info) => {

    Sentry.captureException(error, { extra: info });

    !Config.SUPPRESS_CONSOLE_LOGS && console.error(error, info);
    return error;
});

export const setUserContext = shouldUseSentry(user => {
    Sentry.configureScope(scope => {
        scope.setUser(user);
    });
});
export const removeUserContext = shouldUseSentry(() => {
    Sentry.configureScope(scope => {
        scope.setUser(null);
    });
});
