import __ from 'localization';

export const BRAND_SCOPES = {
    BEATPORT: 'Beatport',
    BEATSOURCE: 'Beatsource',
};

export const APP_SCOPES = {
    BASEWARE: 'app:baseware',
    CONTROL: 'app:control',
};

export const STAFF_SCOPES = {
    ADMIN: 'staff:admin',
    CONTENTOPS: 'staff:content_ops',
    CURATION: 'staff:curation',
    MARKETING: 'staff:marketing',
    LABELMGMT: 'staff:label_mgmt',
    SUPPORT: 'staff:support',
    FINANCE: 'staff:finance',
};

export const ALL_STAFF_SCOPES = [
    [STAFF_SCOPES.ADMIN],
    [STAFF_SCOPES.CONTENTOPS],
    [STAFF_SCOPES.CURATION],
    [STAFF_SCOPES.FINANCE],
    [STAFF_SCOPES.LABELMGMT],
    [STAFF_SCOPES.MARKETING],
    [STAFF_SCOPES.SUPPORT],
];

export const PAGE_EDIT_SCOPES = [
    [STAFF_SCOPES.ADMIN],
    [STAFF_SCOPES.CURATION],
    [STAFF_SCOPES.MARKETING],
];

export const USER_SCOPES = {
    SUPPLIER: 'user:supplier',
    LABEL: 'user:label',
    ARTIST: 'user:artist',
    DJ: 'user:dj',
};

export const LABEL_DETAIL_MAPPINGS_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
];

export const CATALOG_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
];

export const ARTIST_PROFILE_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.LABELMGMT],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
];

export const TRACK_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
];

export const TRACK_DJ_EDIT_SCOPES = [
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.FINANCE],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.LABELMGMT],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.MARKETING],
    [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
];

// for now, since we cannot serve imgs via gcs for beatport
export const IMAGE_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
];

export const USER_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
];

export const COUPON_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.MARKETING],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
];

export const ORDERS_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
];

export const NOTE_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS]
];

export const PERSON_EDIT_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT]
];

export const AUDIOFILE_DOWNLOAD_SCOPES = [
    [APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS],
    [APP_SCOPES.CONTROL, STAFF_SCOPES.SUPPORT],
    [BRAND_SCOPES.BEATSOURCE],
];

export const FEATURES = {
    SALES: 'feature:sales',
    STATEMENTS: 'feature:statements',
};

const SLIDESHOW_RESOURCE_TYPES = [
    'releases',
    'tracks',
    'artists',
    'charts',
    'playlists'
];

export const ALLOWED_TYPES = {
    slideshowSmall: SLIDESHOW_RESOURCE_TYPES,
    slideshowLarge: SLIDESHOW_RESOURCE_TYPES,
    playlistSlideshowLarge: SLIDESHOW_RESOURCE_TYPES,
    homeLargeSlideshow: SLIDESHOW_RESOURCE_TYPES,
    homeLargeSlideshowOld: SLIDESHOW_RESOURCE_TYPES,
    genreLargeSlideshow: SLIDESHOW_RESOURCE_TYPES,
    homeSmallSlideshow: SLIDESHOW_RESOURCE_TYPES,
    genreSmallSlideshow: SLIDESHOW_RESOURCE_TYPES,
    chartsLargeSlideshow: SLIDESHOW_RESOURCE_TYPES,
    chartsLargeSlideshowOld: SLIDESHOW_RESOURCE_TYPES,
    landingPageHeaderSlideshow: SLIDESHOW_RESOURCE_TYPES,
    landingPageLeftSlideshow: SLIDESHOW_RESOURCE_TYPES,
    eventHomeLargeSlideshow: SLIDESHOW_RESOURCE_TYPES,
    mixHomeLargeSlideshow: SLIDESHOW_RESOURCE_TYPES,
    profileFeature: ['artists'],
    releaseFeature: ['releases'],
    mixFeature: ['releases'],
    packFeature: ['releases'],
    landingPageTopDownloads: ['releases'],
    hypeFeature: ['releases'],
    chartFeature: ['charts'],
    labelFeature: ['labels'],
    page: ['page_modules'],
    the20: ['tracks'],
    trackFeature: ['tracks'],
    playlistFeature: ['playlists'],
};

export const MODULE_TO_ITEM = {
    artists: 'performer',
    charts: 'chart',
    labels: 'label',
    releases: 'release',
    suppliers: 'aggregator',
    tracks: 'track',
    playlists: 'chart'
};

export const SLIDESHOW_TYPES = [
    'slideshowSmall',
    'slideshowLarge',
    'playlistSlideshowLarge',
    'homeLargeSlideshow',
    'genreLargeSlideshow',
    'homeSmallSlideshow',
    'genreSmallSlideshow',
    'chartsLargeSlideshow',
    'landingPageHeaderSlideshow',
    'landingPageLeftSlideshow',
    'eventHomeLargeSlideshow',
    'mixHomeLargeSlideshow',
];

const largeSlideshowConfig = {
    aspectRatio: '2.07:1',
    forceRatio: 1,
    outputWidth: 1050,
    outputHeight: 508,
    width: 1050,
    height: 508,
};

export const PAGE_MODULE_IMAGES = {
    chartsLargeSlideshow: largeSlideshowConfig,
    genreLargeSlideshow: largeSlideshowConfig,
    homeCrossMerchandising: {
        aspectRatio: '.94:1',
        forceRatio: 1,
        outputWidth: 480,
        outputHeight: 508,
        width: 480,
        height: 508,
    },
    homeLargeSlideshow: {
        aspectRatio: '1.43:1',
        forceRatio: 1,
        outputWidth: 800,
        outputHeight: 560,
        width: 800,
        height: 560,
    },
    homeLargeSlideshowOld: {
        aspectRatio: '1.43:1',
        forceRatio: 1,
        outputWidth: 920,
        outputHeight: 642,
        width: 920,
        height: 642,
    },
    homeSmallSlideshow: {
        aspectRatio: '2.06:1',
        forceRatio: 1,
        outputWidth: 220,
        outputHeight: 107,
        width: 220,
        height: 107,
    },
    landingPageHeaderSlideshow: {
        aspectRatio: '2.93:1',
        forceRatio: 1,
        outputWidth: 940,
        outputHeight: 321,
        width: 940,
        height: 321,
    },
    landingPageIABMediumRectangleSlideshow: {
        aspectRatio: '1.2:1',
        forceRatio: 1,
        outputWidth: 300,
        outputHeight: 250,
        width: 300,
        height: 250,
    },
    landingPageLeftSlideshow: {
        aspectRatio: '1.93:1',
        forceRatio: 1,
        outputWidth: 620,
        outputHeight: 321,
        width: 620,
        height: 321,
    },
    landingPageUnrestrictedHeightItems: {
        aspectRatio: '4.29:1',
        forceRatio: 1,
        outputWidth: 300,
        outputHeight: 70,
        width: 300,
        height: 70,
    },
    slideshowLarge: {
        aspectRatio: '2.5:1',
        forceRatio: 1,
        outputWidth: 2160,
        outputHeight: 864,
        width: 2160,
        height: 864,
    },
    default: {
        aspectRatio: '1:1',
        forceRatio: 1,
        outputWidth: 1400,
        outputHeight: 1400,
        width: 1400,
        height: 1400,
    },
};

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const MEDIA_QUERIES = {
    MOBILE_PORTRAIT: 0,
    MOBILE_LANDSCAPE: 1,
    TABLET_PORTRAIT: 2,
    TABLET_LANDSCAPE: 3,
    DESKTOP: 4,
    DESKTOP_LARGE: 5,
};

const PAGE_SLOT = {
    HEADER: 1,
    LEFT: 2,
    RIGHT: 3,
};

const RELEASE_FEATURE = {
    [PAGE_SLOT.HEADER]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
    },
    [PAGE_SLOT.LEFT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 8,
            itemsPerRow: 4,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 8,
            itemsPerRow: 4,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 8,
            itemsPerRow: 4,
        },
    },
    [PAGE_SLOT.RIGHT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 6,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 6,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 6,
            itemsPerRow: 2,
        },
    },
};

const SLIDESHOW = {
    [PAGE_SLOT.HEADER]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
    },
    [PAGE_SLOT.LEFT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
    },
    [PAGE_SLOT.RIGHT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 10,
            itemsPerRow: 1,
        },
    },
};

const CHART_FEATURE = {
    [PAGE_SLOT.HEADER]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 10,
            itemsPerRow: 5,
        },
    },
    [PAGE_SLOT.LEFT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 9,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 9,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 9,
            itemsPerRow: 3,
        },
    },
    [PAGE_SLOT.RIGHT]: {
        [MEDIA_QUERIES.MOBILE_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 2,
        },
        [MEDIA_QUERIES.MOBILE_LANDSCAPE]: {
            totalItems: 6,
            itemsPerRow: 3,
        },
        [MEDIA_QUERIES.TABLET_PORTRAIT]: {
            totalItems: 4,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.TABLET_LANDSCAPE]: {
            totalItems: 4,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP]: {
            totalItems: 4,
            itemsPerRow: 1,
        },
        [MEDIA_QUERIES.DESKTOP_LARGE]: {
            totalItems: 4,
            itemsPerRow: 1,
        },
    },
};

export const PAGE_MODULE_CONFIG = {
    chartFeature: CHART_FEATURE,
    chartsLargeSlideshow: SLIDESHOW,
    eventHomeLargeSlideshow: SLIDESHOW,
    genreLargeSlideshow: SLIDESHOW,
    genreSmallSlideshow: SLIDESHOW,
    hypeFeature: RELEASE_FEATURE,
    homeLargeSlideshow: SLIDESHOW,
    homeSmallSlideshow: SLIDESHOW,
    landingPageHeaderSlideshow: SLIDESHOW,
    landingPageLeftSlideshow: SLIDESHOW,
    landingPageTopDownloads: RELEASE_FEATURE,
    mixFeature: RELEASE_FEATURE,
    mixHomeLargeSlideshow: SLIDESHOW,
    nonCatalog: SLIDESHOW,
    packFeature: RELEASE_FEATURE,
    releaseFeature: RELEASE_FEATURE,
    playlistFeature: CHART_FEATURE,
};

export const ACCOUNT_CREDIT_CURRENCIES = [
    'Dollar',
    'Euro',
    'Australian Dollar',
    'Pound',
    'Mexico Pesos',
    'Brazil Real'
];

export const PAGE_SLOT_OPTIONS = [
    { display: 'Select...', value: null },
    { display: 'Header Module', value: '1' },
    { display: 'Left Module', value: '2' },
];

export const PAGE_SLOT_OPTIONS_BP = [
    ...PAGE_SLOT_OPTIONS,
    { display: 'Right Module', value: '3' },
];

export const DEFAULT_IMG_SIZE = {
    width: 1400,
    height: 1400
};

export const CREATE = 'create';
export const UPDATE = 'update';

export const CURRENT_TRACK_STATUS = {
    beatport: {
        classic: 5,
        exclusive: 7,
        general_content: 10,
        new_release: 8,
        pre_order_exclusive: 11,
        pre_order_new_release: 12,
    },
    beatsource: {
        exclusive: 1,
        general_content: 3,
        new_release: 2,
        pre_order_exclusive: 4,
        pre_order_new_release: 5,
    },
};

export const ACTIVE_SOURCE_TYPES = ['sushi', 'twitchtv', 'djwebapp', 'mobileapp'];

export const ACTIVE_BEATSOURCE_PAGE_MODULE_TYPES = [
    'chart feature',
    'release feature',
    'slideshow large',
    'the20',
    'track feature',
    'playlist feature',
];

export const ACTIVE_BEATPORT_PAGE_MODULE_TYPES = [
    'chart',
    'chart feature',
    'charts large slideshow old',
    'charts large slideshow',
    'genre large slideshow',
    'home large slideshow old',
    'home cross merchandising',
    'home small slideshow',
    'hype feature',
    'landing page unrestricted height items',
    'landing page top downloads',
    'landing page iab medium rectangle slideshow',
    'landing page left slideshow',
    'landing page header slideshow',
    'playlist feature',
    'release feature',
    'video feature',
    'youtube feature',
];

export const ASSOCIATION_KEYS = {
    0: __('unavailable'),
    1: __('available'),
    2: __('dissociated'),
};

export const FILE_CLASS_EXTENSIONS = {
    '.flac': {
        isPreview: false,
        format: 'flac',
    },
    '.wav': {
        isPreview: false,
        format: 'wav',
    },
    '.LOFI.mp4': {
        isPreview: true,
        format: 'mp4',
    },
    '.LOFI.mp3': {
        isPreview: true,
        format: 'mp3',
    },
    '.mp3': {
        isPreview: false,
        format: 'mp3',
    },
    '.aiff': {
        isPreview: false,
        format: 'aiff',
    },
    '.128k.aac.mp4': {
        isPreview: false,
        format: 'mp4-aac-128',
    },
    '.256k.aac.mp4': {
        isPreview: false,
        format: 'mp4-aac-256',
    },
};

export const AUDIO_UPLOAD_FILE_TYPE = 'audio/x-wav,audio/flac,audio/x-aiff';
