export const AUTH_VERSION = 1;

const defaultState = {
    scope: '',
    feature: [],
    version: AUTH_VERSION,
};

const auth = (state = defaultState, action) => {
    const { type, ...user } = action;
    switch (type) {
        case 'INIT_AUTH':
            if (state.version !== AUTH_VERSION || !state.scope) {
                return defaultState;
            }
            return state;
        case 'REFRESH_USER':
            return {
                ...defaultState,
                ...user,
            };
        case 'UPDATE_USER':
            return {
                ...defaultState,
                ...state,
                ...user,
            };
        case 'LOGOUT':
            return defaultState;
    }
    return state;
};

export default auth;
