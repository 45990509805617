const API_VERSION = 'v4';
const CATALOG = 'catalog';
const CURATION = 'curation';
const AUXILIARY = 'auxiliary';
const ACCOUNTS = 'accounts';
const CONTENT_OPS = 'content-ops';
const MEDIA = 'media';

const config = {
    ID: 'common',
    API_CATALOG: CATALOG,
    API_AUXILIARY: AUXILIARY,
    API_CURATION: CURATION,
    API_ACCOUNTS: ACCOUNTS,
    API_CONTENT_OPS: CONTENT_OPS,
    API_VERSION: API_VERSION,
    API_HOST: 'api-main.beatportci.com',
    API_IDENTITY_HOST: 'api-main.beatportci.com',
    API_PROTOCOL: 'https://',
    API_ENDPOINT_ARTISTS: `/${API_VERSION}/${CATALOG}/artists/`,
    API_ENDPOINT_FILES: `/${API_VERSION}/al/files/`,
    API_ENDPOINT_AUTH: `/${API_VERSION}/auth/`,
    API_ENDPOINT_AUTH_AUTHORIZE: `/${API_VERSION}/auth/o/authorize/`,
    API_ENDPOINT_AUDIO_FORMATS: `/${API_VERSION}/${CATALOG}/audio-formats/`,
    API_ENDPOINT_AUTH_INTROSPECT: `/${API_VERSION}/auth/o/introspect/`,
    API_ENDPOINT_AUTH_LOGOUT: `/${API_VERSION}/auth/logout/`,
    API_ENDPOINT_AUTH_REDIRECT: `/${API_VERSION}/auth/o/redirect/`,
    API_ENDPOINT_ACCOUNT_TYPES: `/${API_VERSION}/${AUXILIARY}/account-types/`,
    API_ENDPOINT_AUTH_TOKEN: `/${API_VERSION}/auth/o/token/`,
    API_ENDPOINT_BILLING: `/${API_VERSION}/my/billing/`,
    API_ENDPOINT_BILLING_FOR_USER: `/${API_VERSION}/accounts/billing/`,
    API_ENDPOINT_BILLING_INFO_TYPES: `/${API_VERSION}/${AUXILIARY}/billing-info-types/`,
    API_ENDPOINT_COMMERCIAL_MODELS: `/${API_VERSION}/${AUXILIARY}/commercial-model-types/`,
    API_ENDPOINT_CHARTS: `/${API_VERSION}/${CATALOG}/charts/`,
    API_ENDPOINT_CONTACT_TYPES: `/${API_VERSION}/${AUXILIARY}/contact-types/`,
    API_ENDPOINT_CONTRACT_TYPES: `/${API_VERSION}/${CONTENT_OPS}/contract-type/`,
    API_ENDPOINT_COUNTRIES: `/${API_VERSION}/${AUXILIARY}/countries/`,
    API_ENDPOINT_COUPONS: `/${API_VERSION}/${CONTENT_OPS}/coupons/`,
    API_ENDPOINT_CREDITS: `/${API_VERSION}/${ACCOUNTS}/credits/`,
    API_ENDPOINT_CURRENT_STATUSES: `/${API_VERSION}/${AUXILIARY}/current-status/`,
    API_ENDPOINT_CURRENCIES: `/${API_VERSION}/${AUXILIARY}/currency/`,
    API_ENDPOINT_DELIVERIES: `/${API_VERSION}/${CONTENT_OPS}/deliveries/`,
    API_ENDPOINT_DISCOUNT_TYPES: `/${API_VERSION}/${AUXILIARY}/discount-types/`,
    API_ENDPOINT_DJ_PROFILES: `/${API_VERSION}/${CONTENT_OPS}/dj-profiles/`,
    API_ENDPOINT_EXCLUSIVE_PERIODS: `/${API_VERSION}/${AUXILIARY}/exclusive-period/`,
    API_ENDPOINT_GENRES: `/${API_VERSION}/${CATALOG}/genres/`,
    API_ENDPOINT_GENRE_MAPPINGS: `/${API_VERSION}/${CONTENT_OPS}/genre-mappings/`,
    API_ENDPOINT_HYPE_OFFERS: `/${API_VERSION}/${CONTENT_OPS}/hype-offers/`,
    API_ENDPOINT_HYPE_PURCHASES: `/${API_VERSION}/${ACCOUNTS}/hype-purchases/`,
    API_ENDPOINT_HOST_VENDORS: `/${API_VERSION}/${CATALOG}/live-stream-host-vendors/`,
    API_ENDPOINT_IDENTITIES: `/${API_VERSION}/${ACCOUNTS}/identities/`,
    API_ENDPOINT_IMAGE_TYPES: `/${API_VERSION}/${AUXILIARY}/image-types/`,
    API_ENDPOINT_ITEM_TYPES: `/${API_VERSION}/${AUXILIARY}/item-types/`,
    API_ENDPOINT_JOBS_SALES: `/${API_VERSION}/jobs/sales/`,
    API_ENDPOINT_JOBS_SALES_EXPORT: `/${API_VERSION}/jobs/sales-export/`,
    API_ENDPOINT_KEYS: `/${API_VERSION}/${CATALOG}/keys/`,
    API_ENDPOINT_LABELS: `/${API_VERSION}/${CATALOG}/labels/`,
    API_ENDPOINT_LABEL_MANAGER: `/${API_VERSION}/${CONTENT_OPS}/label-managers/`,
    API_ENDPOINT_LABEL_MAPPINGS: `/${API_VERSION}/${CONTENT_OPS}/label-mappings/`,
    API_ENDPOINT_LIVE_STREAMS: `/${API_VERSION}/${CATALOG}/live-streams/`,
    API_ENDPOINT_MEDIA_IMAGES: `/${API_VERSION}/${MEDIA}/images/`,
    API_ENDPOINT_MIME_TYPES: `/${API_VERSION}/${AUXILIARY}/mime-types/`,
    API_ENDPOINT_MUZOOKA_ARTISTS: `/${API_VERSION}/${CATALOG}/artists/muzooka/`,
    API_ENDPOINT_MUZOOKA_MAPPINGS: `/${API_VERSION}/${CATALOG}/artists/muzooka/mappings/`,
    API_ENDPOINT_ON_DEMAND: `/${API_VERSION}/on-demand/`,
    API_ENDPOINT_ORDERS: `/${API_VERSION}/${ACCOUNTS}/orders/`,
    API_ENDPOINT_BEATDROP: `/${API_VERSION}/beatdrop/`,
    API_ENDPOINT_PIPELINE: `/${API_VERSION}/pipeline/`,
    API_ENDPOINT_PAGES: `/${API_VERSION}/${CURATION}/pages/`,
    API_ENDPOINT_PAGE_MODULES: `/${API_VERSION}/${CURATION}/page-modules/`,
    API_ENDPOINT_PAGE_MODULE_TYPES: `/${API_VERSION}/${AUXILIARY}/page-module-types/`,
    API_ENDPOINT_PAGE_TYPES: `/${API_VERSION}/${AUXILIARY}/page-types/`,
    API_ENDPOINT_PLAYLISTS: `/${API_VERSION}/${CATALOG}/playlists/`,
    API_ENDPOINT_PLAYLIST_TYPES: `/${API_VERSION}/${AUXILIARY}/playlist-types/`,
    API_ENDPOINT_PUBLISHERS: `/${API_VERSION}/${CONTENT_OPS}/publishers/`,
    API_ENDPOINT_REASONS: `/${API_VERSION}/${AUXILIARY}/reason/`,
    API_ENDPOINT_REFUNDS: `/${API_VERSION}/${ACCOUNTS}/refund-items/`,
    API_ENDPOINT_RELEASES: `/${API_VERSION}/${CATALOG}/releases/`,
    API_ENDPOINT_RELEASE_NOTES: `/${API_VERSION}/${CATALOG}/releases/`,
    API_ENDPOINT_RELEASE_TYPES: `/${API_VERSION}/${AUXILIARY}/release-types/`,
    API_ENDPOINT_SALE_TYPES: `/${API_VERSION}/${AUXILIARY}/sale-types/`,
    API_ENDPOINT_SEARCH: `/${API_VERSION}/${CATALOG}/search/`,
    API_ENDPOINT_SEARCH_AUTOCOMPLETE: `/${API_VERSION}/${CATALOG}/search/autocomplete/`,
    API_ENDPOINT_SONGWRITER_TYPES: `/${API_VERSION}/${CONTENT_OPS}/publish-songwriter-types/`,
    API_ENDPOINT_SOURCE_TYPES: `/${API_VERSION}/${AUXILIARY}/source-types/`,
    API_ENDPOINT_STATES: `/${API_VERSION}/${AUXILIARY}/states/`,
    API_ENDPOINT_SUPPLIERS: `/${API_VERSION}/${CATALOG}/suppliers/`,
    API_ENDPOINT_SUPPLIERS_FTP: `/${API_VERSION}/${CONTENT_OPS}/ftp-users/`,
    API_ENDPOINT_NEW_USERS_CSV: `/${API_VERSION}/${ACCOUNTS}/users-csv/download/`,
    API_ENDPOINT_USER_ORDER_ITEMS_CSV: `/${API_VERSION}/${ACCOUNTS}/order-items-csv/download/`,
    API_ENDPOINT_TRACKS: `/${API_VERSION}/${CATALOG}/tracks/`,
    API_ENDPOINT_TRACK_FILE_UPDATE: `/${API_VERSION}/${CONTENT_OPS}/track-file-update/`,
    API_ENDPOINT_TRACK_NOTES: `/${API_VERSION}/${CATALOG}/tracks/`,
    API_ENDPOINT_TRACKLIST_VENDORS: `/${API_VERSION}/${CATALOG}/live-stream-tracklist-vendors/`,
    API_ENDPOINT_USERS: `/${API_VERSION}/${ACCOUNTS}/users/`,
    API_ENDPOINT_XSD: `/${API_VERSION}/xsd_files/automated_ingestion/genre/`,
    API_VIEW_HYPE_SUBSCRIPTIONS: `/${API_VERSION}/hype-subscriptions/`,
    API_VIEW_BILLING: `/${API_VERSION}/billing/`,
    INSPECTOR_API: 'inspector.beatdrop.beatportstage.com/v1/deliveries/',
    BEATDROP_BUCKET: 'https://console.cloud.google.com/storage/browser/',
    ROUTE_INDEX: '/',
    ROUTE_AUTH_LOGIN: '/auth',
    ROUTE_AUTH_VERIFY: '/auth/verify',
    ROUTE_LOGOUT: '/logout',
    ROUTE_DASHBOARD: '/dashboard',
    ROUTE_HELP: '/help',
    ROUTE_NEW_USER_EXPORT: '/new-user-export',
    ROUTE_PACKAGING: '/packaging',
    ROUTE_CATALOG: '/catalog',
    ROUTE_CURATION: '/curation',
    ROUTE_DELIVERIES: '/old-deliveries',
    ROUTE_DELIVERIES_BEATDROP: '/deliveries',
    ROUTE_SEARCH: '/search',
    ROUTE_STATEMENTS: '/statements',
    ROUTE_SALES: '/sales',
    ROUTE_ACCOUNT: '/account',
    ROUTE_CREATE: '/create',
    ROUTE_SUPPORT: '/support',
    ROUTE_HYPE: '/hype',
    ROUTE_RESOURCES: '/resources',
    ROUTE_SERVICE_AGREEMENT: '/service-agreement',
    ROUTE_LABEL_MANAGER: '/label-manager',
    ROUTE_LIVE: '/live',
    SENTRY_ENABLED: true,
    SENTRY_DSN: 'https://c89a8756f1644359b4eae58b4b48084b@sentry.io/286112',
    STORE_HOST: 'https://www.beatport.com',
    STORE_BEATSOURCE: 'https://www.beatsource.com',
    // Although sounds are deprecated, this url redirects to loopcloud
    SOUNDS_HOST: 'https://sounds.beatport.com',
    STACKTRACE_ENABLED: false,
    SUPPRESS_CONSOLE_LOGS: false,
    GA_TRACKING_CODE: 'UA-651667-77',
    GA_ENABLED: false,
    GA_TEST_MODE: false,
    MEDIA_QUERIES: [
        { id: 0, name: 'MOBILE_PORTRAIT', mediaQuery: '(max-width: 567px)' },
        { id: 1, name: 'MOBILE_LANDSCAPE', mediaQuery: '(min-width: 568px) and (max-width: 767px)' },
        { id: 2, name: 'TABLET_PORTRAIT', mediaQuery: '(min-width: 768px) and (max-width: 1023px)' },
        { id: 3, name: 'TABLET_LANDSCAPE', mediaQuery: '(min-width: 1024px) and (max-width: 1149px)' },
        { id: 4, name: 'DESKTOP', mediaQuery: '(min-width: 1150px) and (max-width: 1680px)' },
        { id: 5, name: 'DESKTOP_LARGE', mediaQuery: '(min-width: 1681px)' },
    ],
    PLAYER_NEEDLEDROP_ENABLED: false,
    PLAYER_ADVANCED_ENABLED: false,
    AUDIO_FILES_VERIFY_AL: true,
};

export default config;
