// UTILS
import __ from 'localization';
import { BRAND_SCOPES } from 'utils/Constants';
import { parseDigits, parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';
import { store } from 'store';
import { splitCamelCase } from 'utils/Data';

const SELECT_PLACEHOLDER = 'Select...';

export const buildOption = (id, entity) => {
    const current = entity[id];
    const value = id.toString();
    const display = splitCamelCase(current.name);
    if (!display) {
        display = splitCamelCase(current.description);
    }
    return { value, display };
};

export const getStateOptions = formData => {
    if (!formData.country_id) {
        return [{ value: '', display: __('Select...') }];
    }

    const countryId = formData.country_id.toString();
    const states = store.getState().models.states.entities.state;
    const filteredStates = Object.keys(states).reduce((acc, key) => {
        const state = states[key];
        state.country.id.toString() === countryId && acc.push({
            value: state.id,
            display: state.name,
        });

        return acc;
    }, []);

    return [
        { value: '', display: __('Select...') },
        ...filteredStates,
    ];
};

export const getModelDefaultId = (modelKey, entityKey, field, defaultValue) => {
    const model = store.getState().models[modelKey];
    const entities = Object.values(model.entities[entityKey]);
    const defaultObject = entities.find(i => i[field] === defaultValue);
    return defaultObject ? defaultObject.id.toString() : '';
};

export const getExclusivePeriodOptions = (required) => {
    const storeData = store.getState().models.exclusivePeriods.entities.exclusivePeriod;
    const dataOptions = Object.values(storeData).map(data => {
        //Days value was 250 during a transition, kept it like this to allow sorting
        let days = data.days === null ? 250 : data.days;
        return { ...data, days };
    });
    const options = dataOptions.sort((a, b) => a.days - b.days).map(({ days, id }) => {
        let value = id.toString();
        let display = __('None');
        if (days === 14) {
            display = __('2 Weeks');
        }
        if (days === 28) {
            display = __('4 Weeks');
        }
        if (days === 56) {
            display = __('8 Weeks');
        }
        if (days === 250) {
            display = __('Lifetime');
        }
        return { display, value };
    });

    return required
        ? options
        : [{ value: '', display: SELECT_PLACEHOLDER }, ...options];
};

export const getModelOptions = (modelKey, entityKey, required) => {
    const model = store.getState().models[modelKey];
    const entity = model.entities[entityKey];
    const options = model.result
        .map(id => buildOption(id, entity))
        .sort((a, b) => a.display < b.display ? -1 : a.display > b.display ? 1 : 0);

    return required
        ? options
        : [{ value: '', display: SELECT_PLACEHOLDER }, ...options];
};

/*
    This function allows a filter to be added to the model results
    Also, it will return names without using  splitCamelCase
    Current use case is for Genres, where splitCamelCase will cause
    issues on genres that include '-', like converting 'Hip-Hop' to 'Hip- Hop'
*/
export const getModelOptionsFilterable = (modelKey, entityKey, required, filter) => {
    const model = store.getState().models[modelKey];
    const entity = model.entities[entityKey];
    let entityList = model.result
        .map(id => entity[id]);
    if (filter) {
        entityList = filter(entityList);
    }
    const options = entityList.map(({ id, name }) => ({ value: id.toString(), display: name }))
        .sort((a, b) => a.display < b.display ? -1 : a.display > b.display ? 1 : 0);

    return required
        ? options
        : [{ value: '', display: SELECT_PLACEHOLDER }, ...options];
};

export const getRestrictedModelOptions = (modelKey, entityKey, required) => {
    /*
        This function is a tempory fix to restrict the available page types
        for creating pages on beatport only.

        Todo: Add to this switch as other page types become available.
        Todo: Remove this function and replace instance on create/Page.js with getModelOptions
        when the all others page types are available.
    */
    const { APP_BRAND } = Config;
    const { BEATPORT } = BRAND_SCOPES;
    const model = store.getState().models[modelKey];
    const entity = model.entities[entityKey];

    let options;

    if (APP_BRAND === BEATPORT && modelKey === 'pageTypes') {
        options = model.result.reduce((acc, id) => {
            const option = buildOption(id, entity);
            const { display } = option;

            switch (display) {
                case 'Landing Page':
                    acc.push(option);
                    return acc;
                default:
                    return acc;
            }
        }, []);
    }
    else {
        options = model.result.map(id => buildOption(id, entity));
    }

    return required
        ? options
        : [{ value: '', display: SELECT_PLACEHOLDER }, ...options];
};

export const getLabelManagerOptions = (useName, required) => {
    const data = store.getState().models.labelManagers.entities.labelManager;
    if (typeof data === 'object' && Object.keys(data).length > 0) {
        const options = Object.keys(data)
            .filter(key => data[key].enabled)
            .map(key => ({
                value: useName ? data[key].name : data[key].id.toString(),
                display: data[key].name,
            }));

        return required
            ? options
            : [{ value: '', display: SELECT_PLACEHOLDER }, ...options];
    }
    return [{ value: '', display: SELECT_PLACEHOLDER }];
};

/* eslint-disable camelcase */
export const getSubGenreOptions = ({ genre_id: genreId }) => {
    const genres = store.getState().models.genres;
    const genre = genres.entities.genre;
    const subGenreIds = genreId && genre[parseInt(genreId, 10)]
        ? genre[genreId].sub_genres
        : [];
    const subGenreOptions = subGenreIds.map((id) => {
        const current = genres.entities.sub_genre[id];
        return { value: current.id.toString(), display: current.name };
    });

    return [
        { value: '', display: __('Select...') },
        ...subGenreOptions,
    ];
    /* eslint-enable camelcase */
};

export const startDateMaxValidator = (endKey = 'end_date') => (value, formData) => {
    if (!value || !formData[endKey]) {
        return { valid: true };
    }

    const endDate = formData[endKey].indexOf('T') === -1
        ? new Date(formData[endKey].replace(/-/g, '/'))
        : new Date(formData[endKey]);

    return value.getTime() - endDate.getTime() > 0
        ? { valid: false, message: __('Start Date must be before End Date') }
        : { valid: true };
};

export const endDateMinValidator = (startKey = 'start_date') => (value, formData) => {
    if (!value || !formData[startKey]) {
        return { valid: true };
    }

    const startDate = formData[startKey].indexOf('T') === -1
        ? new Date(formData[startKey].replace(/-/g, '/'))
        : new Date(formData[startKey]);

    return value.getTime() - startDate.getTime() < 0
        ? { valid: false, message: __('End Date must be after Start Date') }
        : { valid: true };
};

export const autoCompleteIdValidator = (autoCompleteKey, errorMessage) => (value, formData) => {
    const valueId = formData[autoCompleteKey];

    if (value !== valueId && typeof valueId === 'number') {
        return { valid: true };
    }

    return { valid: false, message: errorMessage || __('Please select a valid option from the list') };
};

export const idOrNull = (data, key) => data[key] ? data[key].id : null;

/**
 *
 * @param {Number|String} value - The current id based on the model
 * @param {Array} options - The list of options for the select input
 * @returns {String} returns the display value for a select input
 */
export const createSelectDisplay = (value, options) => {
    const id = value ? value.toString() : value;
    const option = options.find(opt => opt.value === id);
    return option ? option.display : '';
};

/**
 *
 * @param {Number|String} value - The current id based on the model
 * @param {Array} options - The list of options from `getModelOptions`
 * @returns {String} returns the display value for a select input based on the `getModelOptions` function
 */
export const createModelSelectDisplay = (value, options) => {
    const id = value ? value.toString() : value;
    const option = options.find(opt => opt.value === id);
    return option ? { id, name: option.display } : null;
};

export const isValidLocalPhoneNumber = value => {
    if (!value) return { valid: true, message: __('empty entry') };

    let localNumber;
    try {
        localNumber = parsePhoneNumber(parseDigits(value), { defaultCountry: 'US' });
    }
    catch (error) {
        return { valid: false, message: error.message };
    }
    return localNumber && localNumber.isValid()
        ? { valid: true, message: __('valid phone number') }
        : { valid: false, message: __('invalid phone number') };
};

export const isValidPhoneNumber = value => {
    if (!value) return { valid: true, message: __('empty entry') };
    let phoneNumber;

    try {
        phoneNumber = parsePhoneNumberFromString('+' + parseDigits(value));
    }
    catch (error) {
        return { valid: false, message: error.message };
    }
    return phoneNumber && phoneNumber.isValid()
        ? { valid: true, message: __('valid phone number') }
        : { valid: false, message: __('invalid phone number') };
};

export const isValidForeignOrLocalPhoneNumber = value => {
    const foreign = isValidPhoneNumber(value);
    const local = isValidLocalPhoneNumber(value);
    return foreign.valid ? foreign : local;
};

export const isValidDdexPartyId = value => {
    if (!value) return { valid: true, message: __('empty entry') };

    // Example: PADPIDA3897722461G.  The final checksum character is made optional
    // due to some suppliers not sending it in their DDEX XML.
    const ddexRegex = /^PADPIDA\d{10}[A-Z0-9]?$/;
    const result = ddexRegex.test(value);
    return { valid: result, message: result ? __('Valid DDEX Party ID') : __('Invalid DDEX Party ID') };
};

export const cleanFieldsOnBrand = fields => {
    return fields.filter(f => !f.displayBrandScope || (f.displayBrandScope === Config.APP_BRAND));
};
