const initPrefs = { filters: {}, columns: {} };
const PREFERENCES_VERSION = 2;

const preferences = (state = {}, action) => {
    switch (action.type) {
        case 'PREFERENCES_INIT':
            if (state[action.userId] && state.version === PREFERENCES_VERSION) {
                return state;
            }

            return {
                ...state,
                [action.userId]: { ...initPrefs },
                version: PREFERENCES_VERSION,
            };

        case 'PREFERENCES_UPDATE_FILTERS':
            return {
                ...state,
                [action.userId]: {
                    ...state[action.userId],
                    filters: {
                        ...state[action.userId].filters,
                        [action.key]: action.filters,
                    },
                },
            };

        case 'PREFERENCES_UPDATE_COLUMNS':
            return {
                ...state,
                [action.userId]: {
                    ...state[action.userId],
                    columns: {
                        ...state[action.userId].columns,
                        [action.key]: action.columns,
                    },
                },
            };

        case 'PREFERENCES_CLEAR':
            return {};

        default:
            return state;
    }
};

export { preferences };
