// SETUP
import { contentTypes } from 'types';
// UTILS
import { Rest } from 'utils/Rest';
import { store } from 'store';
import qs from 'qs';
import { getBigQuery } from 'integrations/BigQuery';

/**
 * Take a function, return a new function with the JWT auth header as the first argument `auth`.
 *
 * @param {function} function to wrap
 * @returns {function} wrapped function, auth applied as first argument
 */
const authorizedRequest = (f) => (...args) => {
    const token = `Bearer ${store.getState().auth.access_token}`;
    return f(...[token, ...args]);
};

export const fetchAccessToken = (payload) => {
    const endpoint = `${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_TOKEN}`;
    return fetch(endpoint + '?' + qs.stringify(payload), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
    })
        .then(r => r.json());
};

export const getContentByType = authorizedRequest((auth, contentType, payload) => {
    let endpoint = contentType.replace(/\_/g, '-');
    if (contentTypes[contentType].overrideEndpoint) {
        endpoint = contentTypes[contentType].overrideEndpoint;
    }
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}/${Config.API_VERSION}/${contentTypes[contentType].category}/${endpoint}/`, payload, auth);
});

export const introspectUser = authorizedRequest((auth) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH_INTROSPECT}`, {}, auth);
});

export const fetchBilling = authorizedRequest(auth =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BILLING}`, {}, auth)
);

export const postBilling = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BILLING}`, payload, auth)
);

export const fetchBillingForUser = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BILLING_FOR_USER}`, payload, auth);
});

export const patchBillingForUser = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BILLING_FOR_USER}${id}/`, payload, auth);
});

export const fetchGenres = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_GENRES}`, payload, auth);
});

export const fetchKeys = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_KEYS}`, payload, auth);
});

export const fetchCommercialModels = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_COMMERCIAL_MODELS}`,payload, auth);
});

export const fetchSaleTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SALE_TYPES}`, payload, auth);
});
export const fetchPlaylistTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLIST_TYPES}`, payload, auth);
});

export const fetchReleaseTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASE_TYPES}`, payload, auth);
});

export const fetchExclusivePeriods = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_EXCLUSIVE_PERIODS}`, payload, auth);
});

export const fetchAudioFormats = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUDIO_FORMATS}`, payload, auth);
});

export const fetchContractTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CONTRACT_TYPES}`, payload, auth);
});

export const fetchContactTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CONTACT_TYPES}`, payload, auth);
});

export const fetchTracks = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}`, payload, auth);
});

export const fetchTrack = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/`, {}, auth);
});

export const fetchTrackNotes = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/notes/`, {}, auth);
});

export const fetchTrackSongwriters = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/songwriters/`, {}, auth);
});

export const fetchTrackPublishers = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/publishers/`, {}, auth);
});

export const postPublisher = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PUBLISHERS}`, payload, auth);
});

export const deletePublisher = authorizedRequest((auth, id, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PUBLISHERS}${id}/`, payload, auth);
});

export const bulkCreateTrackPublishers = authorizedRequest((auth, trackId, payload) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${trackId}/publishers/`, payload, auth);
});

export const fetchTrackAudioFiles = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/audio-files/`, {}, auth);
});

export const postTrackReEncode = authorizedRequest((auth, id) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/reencode/`, {}, auth);
});

export const postReleaseReEncode = authorizedRequest((auth, id) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/reencode/`, {}, auth);
});

export const postTrackEncodeOnDemand = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ON_DEMAND}encode/`, payload, auth);
});

export const postTrackPipelineEncode = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PIPELINE}encode/`, payload, auth);
});

export const postTrackBeatdropEncode = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BEATDROP}encode/`, payload, auth);
});

export const fetchTrackSupportDownload = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/support-downloads/`, payload, auth);
});

export const fetchTrackCountriesPublishDate = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-publish-date/`, payload, auth);
});

export const fetchCmtTrackCountriesPublishDate = authorizedRequest((auth, id, cmt, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-publish-date/?commercial_model_type=${cmt}`, payload, auth);
});

export const putTrackCountriesPublishDate = authorizedRequest((auth, id, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-publish-date/`, payload, auth);
});

export const fetchTrackTerritoryRestrictionRegions = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-allowed/regional-summary/`, {}, auth);
});

export const fetchTrackTerritoryRestrictionCountries = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-allowed/`, payload, auth);
});

export const putTrackTerritoryRestrictionCountries = authorizedRequest((auth, id, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-allowed/`, payload, auth);
});

export const postSyncTrackTerritories = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-allowed/`, payload, auth);
});

export const patchTrack = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/`, payload, auth);
});

export const bulkPatchTracks = authorizedRequest((auth, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}bulk/`, payload, auth);
});

export const fetchReleases = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}`, payload, auth);
});

export const fetchRelease = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/`, {}, auth);
});

export const fetchReleaseNotes = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/notes/`, {}, auth);
});

export const fetchReleaseTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/tracks/`, payload, auth);
});

export const bulkPatchReleaseTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/tracks/bulk/`, payload, auth);
});

export const patchRelease = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/`, payload, auth);
});

export const postRelease = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}`, payload, auth);
});

export const fetchChart = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/`, {}, auth);
});

export const fetchChartPlaylistMapping = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/playlist-mappings/`, {}, auth);
});

export const fetchPlaylistPartners = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/partners/`, {}, auth);
});

export const fetchChartTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/tracks/`, payload, auth);
});

export const putChartTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/tracks/`, payload, auth);
});

export const putPlaylistTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/tracks/bulk/`, payload, auth);
});

export const putPlaylistPartners = authorizedRequest((auth, id, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/partners-bulk/`, payload, auth);
});

export const postArtist = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ARTISTS}`, payload, auth)
);

export const postArtistImage = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ARTISTS}${id}/images/`, payload, auth);
});

export const fetchArtist = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ARTISTS}${id}/`, {}, auth);
});

export const patchArtist = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ARTISTS}${id}/`, payload, auth);
});

export const fetchDjProfile = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DJ_PROFILES}${id}/`, {}, auth);
});

export const fetchDjProfiles = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DJ_PROFILES}`, payload, auth);
});

export const fetchCoupon = authorizedRequest((auth, id) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_COUPONS}${id}/`, {}, auth)
);

export const patchCoupon = authorizedRequest((auth, id, payload) =>
    Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_COUPONS}${id}/`, payload, auth)
);

export const fetchLabel = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/`, {}, auth);
});

export const fetchLabels = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}`, payload, auth);
});

export const patchLabel = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/`, payload, auth);
});

export const fetchLabelContracts = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/contracts/`, {}, auth);
});

export const patchLabelContract = authorizedRequest((auth, labelId, contractId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${labelId}/contracts/${contractId}/`, payload, auth);
});

export const postLabelContract = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/contracts/`, payload, auth);
});

export const postSupplier = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}`, payload, auth)
);

export const fetchSupplier = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/`, {}, auth);
});

export const fetchSupplierLabels = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/labels/`, payload, auth);
});

export const fetchSupplierLabelMappings = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/label-mappings/`, payload, auth);
});

export const fetchLabelMappings = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/mappings/`, payload, auth);
});

export const fetchSupplierGenreMappings = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/genre-mappings/`, payload, auth);
});

export const fetchSupplierContracts = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contracts/`, {}, auth);
});

export const fetchSupplierSubscribers = authorizedRequest((auth, id, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/subscribers/`, payload, auth);
});

export const fetchSupplierContacts = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contacts/`, {}, auth);
});

export const fetchSupplierNotes = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/notes/`, {}, auth);
});

export const fetchCountries = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_COUNTRIES}`, payload, auth);
});

export const fetchCurrentStatuses = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CURRENT_STATUSES}`, payload, auth);
});

export const searchCatalog = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SEARCH}`, payload, auth);
});

export const searchAutocomplete = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SEARCH_AUTOCOMPLETE}`, payload, auth);
});

export const fetchSupplierStatement = authorizedRequest((auth, supplierId, statementId, appStore = 'music') => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${supplierId}/statements-${appStore}/${statementId}/`, {}, auth);
});

export const fetchLabelStatement = authorizedRequest((auth, labelId, statementId, appStore = 'music') => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${labelId}/statements-${appStore}/${statementId}/`, {}, auth);
});

export const fetchLabelStatements = authorizedRequest((auth, labelId, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${labelId}/statements-music/`, payload, auth);
});

export const fetchStatements = authorizedRequest((auth, id, payload = {}, appStore = 'music') => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/statements-${appStore}/`, payload, auth);
});

export const fetchStatementsCSV = authorizedRequest((auth, id, payload = {}, appStore = 'music-daily') => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/statements-${appStore}/download/`, payload, auth, { 'Content-Type': 'text/csv' });
});

export const fetchNewUsersCSV = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_NEW_USERS_CSV}`, payload, auth, { 'Content-Type': 'text/csv' });
});

export const fetchLabelExportCSV = authorizedRequest((auth, labelId, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${labelId}/download/`, payload, auth, { 'Content-Type': 'text/csv' });
});

export const patchSupplier = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/`, payload, auth);
});

export const patchSupplierContact = authorizedRequest((auth, id, contactId, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contacts/${contactId}/`, payload, auth);
});

export const postSupplierContact = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contacts/`, payload, auth);
});

export const deleteSupplierContact = authorizedRequest((auth, id, contactId, payload = {}) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contacts/${contactId}/`, payload, auth);
});

export const fetchUser = authorizedRequest((auth, id, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}${id}/`, payload, auth);
});

export const fetchUserNotes = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}${id}/notes/`, {}, auth);
});

export const postUserNotes = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}${id}/notes/`, payload, auth);
});

export const patchUser = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}${id}/`, payload, auth);
});

export const bulkPatchUsers = authorizedRequest((auth, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}`, payload, auth);
});

export const patchUserDjProfile = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USERS}${id}/dj-profile/`, payload, auth);
});

export const patchDjProfile = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DJ_PROFILES}${id}/`, payload, auth);
});

export const deleteDjProfileArtist = authorizedRequest((auth, id, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DJ_PROFILES}${id}/artist-association/`, payload, auth);
});

export const fetchPageModuleTypes = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULE_TYPES}`, payload, auth);
});

export const fetchPageModule = authorizedRequest((auth, id, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/`, payload, auth);
});

export const postPageModule = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}`, payload, auth);
});

export const patchPageModule = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/`, payload, auth);
});

export const deletePageModule = authorizedRequest((auth, id, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/`, payload, auth);
});

export const fetchPageModuleItems = authorizedRequest((auth, id, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/items/`, payload, auth);
});

export const postPageModuleItem = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/items/`, payload, auth);
});

export const patchPageModuleItem = authorizedRequest((auth, id, itemId, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/items/${itemId}/`, payload, auth);
});

export const deletePageModuleItems = authorizedRequest((auth, id, itemId, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_MODULES}${id}/items/${itemId}/`, payload, auth);
});

export const fetchItemTypes = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ITEM_TYPES}`, payload, auth);
});

export const fetchPage = authorizedRequest((auth, id, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGES}${id}/`, payload, auth);
});

export const postPage = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGES}`, payload, auth);
});

export const patchPage = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGES}${id}/`, payload, auth);
});

export const deletePage = authorizedRequest((auth, id, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGES}${id}/`, payload, auth);
});

export const fetchPageTypes = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PAGE_TYPES}`, payload, auth);
});

export const fetchStates = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_STATES}`, payload, auth);
});

export const fetchSalesJob = authorizedRequest((auth, id, payload = {}) => {
    const { type, ...extra } = payload;

    const endpoint = type
        ? `${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/sales/${type}/`
        : `${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/sales/`;

    return Rest.get(endpoint, extra, auth);
});

export const fetchSalesJobResults = authorizedRequest((auth, id, payload = {}) => {
    const { type, ...extra } = payload; // eslint-disable-line no-unused-vars
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_JOBS_SALES}${id}/`, extra, auth);
});

export const fetchSales = authorizedRequest((auth, id, payload) => getBigQuery(id, payload, fetchSalesJob, fetchSalesJobResults));

export const fetchSalesReportJob = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/sales-export/`, payload, auth);
});

export const fetchSalesReportJobResults = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_JOBS_SALES_EXPORT}${id}/`, payload, auth);
});

export const fetchSalesExport = authorizedRequest((auth, id, payload) => getBigQuery(id, payload, fetchSalesReportJob, fetchSalesReportJobResults));

export const fetchAccountTypes = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ACCOUNT_TYPES}`, payload, auth)
);

export const fetchBillingInfoTypes = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_BILLING_INFO_TYPES}`, payload, auth)
);

export const patchContentByType = authorizedRequest((auth, contentType, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}/${Config.API_VERSION}/${contentTypes[contentType].category}/${contentType.replace('_', '-')}/${id}/`, payload, auth);
});

export const putContentByType = authorizedRequest((auth, contentType, payload) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}/${Config.API_VERSION}/${contentTypes[contentType].category}/${contentType.replace('_', '-')}/`, payload, auth);
});

export const fetchCurrencies = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CURRENCIES}`, payload, auth)
);

export const fetchReasons = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_REASONS}`, payload, auth)
);

export const fetchDiscountTypes = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DISCOUNT_TYPES}`, payload, auth)
);

export const fetchHypeOffer = authorizedRequest((auth, id) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_OFFERS}${id}/`, {}, auth)
);

export const fetchHypeOffers = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_OFFERS}`, payload, auth)
);

export const patchHypeOffer = authorizedRequest((auth, id, payload) =>
    Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_OFFERS}${id}/`, payload, auth)
);

export const postHypeOffer = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_OFFERS}`, payload, auth)
);

export const postChart = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}`, payload, auth)
);

export const patchChart = authorizedRequest((auth, id, payload) =>
    Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/`, payload, auth)
);

export const postChartPlaylistMapping = authorizedRequest((auth, id, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/playlist-mappings/`, payload, auth)
);

export const patchChartPlaylistMapping = authorizedRequest((auth, id, mappingId, payload) =>
    Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/playlist-mappings/${mappingId}/`, payload, auth)
);

export const fetchPlaylists = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}`, payload, auth)
);

export const fetchPlaylist = authorizedRequest((auth, id, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/`, payload, auth)
);

export const fetchPlaylistChartMapping = authorizedRequest((auth, id, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/chart-mapping/`, payload, auth)
);

export const fetchPlaylistTracks = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/tracks/`, payload, auth);
});

export const postPlaylist = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}`, payload, auth)
);

export const patchPlaylist = authorizedRequest((auth, id, payload) =>
    Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_PLAYLISTS}${id}/`, payload, auth)
);

export const fetchHypeOfferLabels = authorizedRequest((auth, id, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_OFFERS}${id}/labels/`, payload, auth)
);

export const triggerTempAccountEmail = authorizedRequest((auth, payload) =>
    Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_AUTH}temp-account-email/`, payload, auth)
);

export const postSupplierAgreement = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/service-agreement/`, payload, auth);
});

export const postSupplierContract = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contracts/`, payload, auth);
});

export const patchSupplierContract = authorizedRequest((auth, id, contractId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/contracts/${contractId}/`, payload, auth);
});

export const postSupplierLabelMapping = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/label-mappings/`, payload, auth);
});

export const patchSupplierLabelMapping = authorizedRequest((auth, id, labelMappingId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/label-mappings/${labelMappingId}/`, payload, auth);
});

export const deleteSupplierLabelMapping = authorizedRequest((auth, id, labelMappingId, payload = {}) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/label-mappings/${labelMappingId}/`, payload, auth);
});

export const postSupplierGenreMapping = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/genre-mappings/`, payload, auth);
});

export const patchSupplierGenreMapping = authorizedRequest((auth, id, genreMappingId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/genre-mappings/${genreMappingId}/`, payload, auth);
});

export const deleteSupplierGenreMapping = authorizedRequest((auth, id, genreMappingId, payload = {}) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/genre-mappings/${genreMappingId}/`, payload, auth);
});

export const postSupplierNote = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/notes/`, payload, auth);
});

export const patchSupplierNote = authorizedRequest((auth, id, noteId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/notes/${noteId}/`, payload, auth);
});

export const deleteSupplierNote = authorizedRequest((auth, id, noteId, payload = {}) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS}${id}/notes/${noteId}/`, payload, auth);
});

export const fetchLabelManagers = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABEL_MANAGER}`, payload, auth);
});

export const postLabelManagers = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABEL_MANAGER}`, payload, auth);
});

export const postLabel = authorizedRequest((auth, payload) =>
    Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}`, payload, auth)
);

export const postLabelImage = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABELS}${id}/images/`, payload, auth);
});

export const patchLabelManagers = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABEL_MANAGER}${id}/`, payload, auth);
});

export const fetchHypePurchase = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HYPE_PURCHASES}${id}/`, payload, auth);
});

export const fetchAssetOriginFiles = authorizedRequest((auth, guid) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_FILES}?guid=${guid}`, {}, auth);
});

export const fetchFileClasses = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}/${Config.API_VERSION}/al/fileclasses/`, payload, auth);
});

export const fetchDeliveries = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DELIVERIES}`, payload, auth);
});

export const resubmitDeliveries = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DELIVERIES}resubmit/`, payload, auth);
});

export const postChartImage = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CHARTS}${id}/images/`, payload, auth);
});

export const postReleaseImage = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/images/`, payload, auth);
});

export const postReleaseNotes = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/notes/`, payload, auth);
});

export const postImage = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MEDIA_IMAGES}`, payload, auth);
});

export const fetchMimeTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MIME_TYPES}`, payload, auth);
});

export const fetchImageTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_IMAGE_TYPES}`, payload, auth);
});

export const fetchSourceTypes = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SOURCE_TYPES}`, payload, auth);
});

export const fetchSongwriterTypes = authorizedRequest((auth, payload) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SONGWRITER_TYPES}`, payload, auth);
});

export const postSongwriter = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/songwriters/`, payload, auth);
});

export const postTrackNotes = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/notes/`, payload, auth);
});

export const postTrack = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}`, payload, auth);
});

export const deleteSongwriter = authorizedRequest((auth, id, itemId, payload) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/songwriters/${itemId}/`, payload, auth);
});

export const fetchIdentities = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_IDENTITY_HOST}${Config.API_ENDPOINT_IDENTITIES}`, payload, auth);
});

export const patchIdentity = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_IDENTITY_HOST}${Config.API_ENDPOINT_IDENTITIES}${id}/`, payload, auth);
});

export const bulkPatchIdentityStatus = authorizedRequest((auth, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_IDENTITY_HOST}${Config.API_ENDPOINT_IDENTITIES}bulk-status/`, payload, auth);
});

export const setUserPassword = authorizedRequest((auth, id, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_IDENTITY_HOST}${Config.API_ENDPOINT_IDENTITIES}${id}/set-password/`, payload, auth);
});

export const getResetPasswordToken = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_IDENTITY_HOST}${Config.API_ENDPOINT_IDENTITIES}${id}/reset-password-token/`, {}, auth);
});

export const createCoupon = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_COUPONS}`, payload, auth);
});

export const createUserCredit = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CREDITS}`, payload, auth);
});

export const bulkCreateCredits = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CREDITS}bulk/`, payload, auth);
});

export const fetchCredits = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_CREDITS}`, payload, auth);
});

export const fetchStreamUrl = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/stream/`, payload, auth);
});

export const fetchOrders = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}`, payload, auth);
});

export const fetchOrder = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${id}`, payload, auth);
});

export const fetchOrderItems = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${id}/items/`, payload, auth);
});

export const fetchOrderItemCSV = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${id}/items/download/`, payload, auth, { 'Content-Type': 'text/csv' });
});

export const fetchOrderItemsCSV = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_USER_ORDER_ITEMS_CSV}`, payload, auth, { 'Content-Type': 'text/csv' });
});

export const fetchOrderItemDownloads = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${id}/downloads/`, payload, auth);
});

export const fetchOrderItemRefunds = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_REFUNDS}`, payload, auth);
});

export const fetchOrderItemRefund = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_REFUNDS}${id}/`, payload, auth);
});

export const fetchOrderItemsRefundSummary = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${id}/items/refund/`, payload, auth);
});

export const createOrderItemRefund = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_REFUNDS}`, payload, auth);
});

export const enableItemDownload = authorizedRequest((auth, orderId, trackId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_ORDERS}${orderId}/downloads/${trackId}/`, payload, auth);
});

export const upsertSupplierGenreMapping = authorizedRequest((auth, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_GENRE_MAPPINGS}`, payload, auth);
});

export const upsertSupplierLabelMapping = authorizedRequest((auth, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LABEL_MAPPINGS}`, payload, auth);
});

export const createLiveStream = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}`, payload, auth);
});

export const fetchLiveStream = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/`, payload, auth);
});

export const patchLiveStream = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/`, payload, auth);
});

export const createLiveStreamStage = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/stages/`, payload, auth);
});

export const deleteLiveStreamStage = authorizedRequest((auth, id, stageId, payload = {}) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/stages/${stageId}/`, payload, auth);
});

export const fetchLiveStreamStages = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/stages/`, payload, auth);
});

export const fetchLiveStreamStage = authorizedRequest((auth, id, stageId, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/stages/${stageId}/`, payload, auth);
});

export const patchLiveStreamStage = authorizedRequest((auth, id, stageId, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_LIVE_STREAMS}${id}/stages/${stageId}/`, payload, auth);
});

export const createHostVendor = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HOST_VENDORS}`, payload, auth);
});

export const fetchHostVendor = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HOST_VENDORS}${id}/`, payload, auth);
});

export const fetchHostVendors = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HOST_VENDORS}`, payload, auth);
});

export const patchHostVendor = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_HOST_VENDORS}${id}/`, payload, auth);
});

export const createTracklistVendor = authorizedRequest((auth, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKLIST_VENDORS}`, payload, auth);
});

export const fetchTracklistVendor = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKLIST_VENDORS}${id}/`, payload, auth);
});

export const fetchTracklistVendors = authorizedRequest((auth, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKLIST_VENDORS}`, payload, auth);
});

export const patchTracklistVendor = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKLIST_VENDORS}${id}/`, payload, auth);
});

export const patchTrackFileUpdate = authorizedRequest((auth, id, payload = {}) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACK_FILE_UPDATE}${id}/`, payload, auth);
});

export const fetchDjEditRelease = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/dj-edit/`, payload, auth);
});

export const createtDjEditRelease = authorizedRequest((auth, id, payload = {}) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/dj-edit/`, payload, auth);
});

export const fetchDjEditSource = authorizedRequest((auth, id, payload = {}) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/dj-edit-source/`, payload, auth);
});

export const fetchSupplierFTP = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS_FTP}?supplier_id=${id}`, {}, auth);
});

export const createSupplierFTP = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS_FTP}`, payload, auth);
});

export const patchSupplierFTP = authorizedRequest((auth, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_SUPPLIERS_FTP}`, payload, auth);
});

export const fetchXsdSchema = authorizedRequest((auth) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_XSD}`, {}, auth);
});

export const searchMuzookaArtist = authorizedRequest((auth, artist = '') => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_ARTISTS}?name=${artist}`, {}, auth);
});

export const fetchMuzookaArtist = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}${id}`, {}, auth);
});

export const postMuzookaArtist = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}`, payload, auth);
});

export const patchMuzookaArtist = authorizedRequest((auth, id, payload) => {
    return Rest.patch(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}${id}/`, payload, auth);
});

export const deleteMuzookaArtist = authorizedRequest((auth, id) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}${id}/`, {}, auth);
});

export const postMuzookaSync = authorizedRequest((auth, payload) => {
    return Rest.post(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}sync/`, payload, auth);
});

export const postMuzookaImagePull = authorizedRequest((auth, id) => {
    return Rest.postWithStatus(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}${id}/pull-image/`, {}, auth);
});

export const deleteMuzookaSync = authorizedRequest((auth,id) => {
    return Rest.delete(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_MUZOOKA_MAPPINGS}${id}/sync/`, {}, auth);
});

export const putTrackPublishTime = authorizedRequest((auth,id, payload) => {
    return Rest.putWithStatus(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_TRACKS}${id}/countries-times/update/`, payload, auth);
});

export const putReleasePublishTime = authorizedRequest((auth,id, payload) => {
    return Rest.putWithStatus(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_RELEASES}${id}/countries-times/update/`, payload, auth);
});

export const getSignedURLForXML = authorizedRequest((auth, payload = {}) => {
    return Rest.put(`${Config.API_PROTOCOL}${Config.API_HOST}${Config.API_ENDPOINT_DELIVERIES}xml/`, payload, auth);
});

export const getBeatdropDeliveries = authorizedRequest((auth, payload = {}) => {
    const { pageParam = '', params = '' } = payload;
    const cursor = pageParam ? `&cursor=${pageParam}` : '';
    return Rest.get(`${Config.API_PROTOCOL}${Config.INSPECTOR_API}?${params}&brand=${Config.APP_BRAND}${cursor}`, {}, auth);
});

export const getDeliveryDetail = authorizedRequest((auth, id) => {
    return Rest.get(`${Config.API_PROTOCOL}${Config.INSPECTOR_API}${id}/`, {}, auth);
});

export const reIngestDeliveries = authorizedRequest((auth, payload) => {
    const { deliveryIds = [], overrideGenre = false } = payload;
    return Rest.post(`${Config.API_PROTOCOL}${Config.INSPECTOR_API}reingest/?override_genre=${overrideGenre}`, deliveryIds, auth);
});
