const initialState = {
    open: true,
    displayType: null,
    filters: {},
    cache: {},
    types: [],
};

export const search = (state = initialState, action) => {
    switch (action.type) {
        case 'SEARCH_UPDATE_DISPLAY_TYPE':
            return {
                ...state,
                displayType: action.value,
            };

        case 'SEARCH_UPDATE_TYPES':
            return {
                ...state,
                types: action.types,
            };

        case 'SEARCH_TOGGLE_DRAWER':
            let open = (typeof action.open !== 'undefined') ? action.open : !state.open;
            return {
                ...state,
                open,
            };

        case 'SEARCH_SET_FILTERS':
            return action.displayType
                ? {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.displayType]: action.filters || {},
                    },
                } : {
                    ...state,
                    filters: {},
                };

        case 'SEARCH_SET_PAYLOAD':
            return {
                ...state,
                payload: action.payload,
            };

        case 'SEARCH_CACHE_ADD':
            return {
                ...state,
                cache: {
                    ...state.cache,
                    [action.cacheKey]: {
                        ...state.cache[action.cacheKey],
                        [action.page]: action.data,
                    },
                },
            };

        case 'SEARCH_CACHE_INIT':
            return {
                ...state,
                cache: {
                    ...state.cache,
                    [action.cacheKey]: {},
                },
            };

        case 'SEARCH_CACHE_CLEAR':
            return {
                ...state,
                cache: {},
            };

        default:
            return state;
    }
};
