// UTILS
export const POLL_TIMEOUT = 120000; // 2 minutes
export const POLL_INTERVAL = 1000; // 1 second

const poll = (response, resolve, reject, payload, fetchJobResults) => {
    let elapsed = 0;
    let dispatchRequest = true;
    let jobId = response.id;

    const interval = setInterval(() => {

        elapsed += POLL_INTERVAL;

        if (elapsed >= POLL_TIMEOUT) {
            clearInterval(interval);
            reject('Request Timed Out');
        }

        if (dispatchRequest) {
            dispatchRequest = false;

            fetchJobResults(jobId, payload)
                .then(res => {
                    if (res.state === 'DONE') {
                        if (res.child_job_id) {
                            dispatchRequest = true;
                            jobId = res.child_job_id;
                        }
                        else {
                            clearInterval(interval);
                            resolve(res);
                        }
                    }
                    else {
                        dispatchRequest = true;
                    }
                })
                .catch(e => {
                    clearInterval(interval);
                    reject(e.message);
                });
        }
    }, POLL_INTERVAL);
};

export const getBigQuery = (id, payload, fetchJob, fetchJobResults) => {
    return new Promise((resolve, reject) => {
        fetchJob(id, payload)
            .then(response => poll(response, resolve, reject, payload, fetchJobResults))
            .catch(e => reject(e.message));
    });
};
