/**
 * ServerError extends Error but has special status property.
 *
 * Constructed like a normal Error with `status` argument.
 *
 * new ServerError('Not found.', 404);
*/
class ServerError extends Error {
    constructor (message, status, response, name) {
        super(message);
        this.status = status;
        this.response = response;
        this.name = name || '';
    }
}

export { ServerError };
