// PACKAGES
import React from 'react';
import ReactDOM from 'react-dom';
// COMPONENTS
import Router from '../Router';
import Bootstrap from '../bootstrap';

import '@styles/base.scss';

ReactDOM.render(
    <Bootstrap>
        <Router />
    </Bootstrap>,
    document.getElementById('app')
);
