// UTILS
import Config from '../config/config.common';
import { filters } from './filters';
// COMPONENTS
import { scopeCheck } from 'components/Permissions/Permissions';
import { STAFF_SCOPES, USER_SCOPES, APP_SCOPES } from 'utils/Constants';

export const contentTypes = {
    _tracks: {
        id: 'tracks',
        display: 'Tracks',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.SUPPLIER],
            [USER_SCOPES.LABEL],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        actions: true,
        fixedSearchColumns: 3,
        filters: [
            'type',
            'id',
            'name',
            'guid',
            'mix_name',
            'release_name',
            'release_id',
            'artist_name',
            'artist_id',
            'label_name',
            'label_id',
            // TODO: Re-enable when API re-enables
            // 'supplier_name',
            // 'supplier_id',
            'label_manager',
            'bpm',
            'key_name',
            'isrc',
            'genre_id',
            'sub_genre_id',
            'is_hype',
            'encode_status',
            'encoded_date',
            'enabled',
            'is_explicit',
            'sale_type',
            'publish_date',
            'exclusive_date',
            'exclusive_period',
            'new_release_date',
            'pre_order_date',
            'free_download_start_date',
            'free_download_end_date',
            'is_available_for_streaming',
            'dj_edits',
            'ugc_remixes',
        ],
    },
    _artists: {
        id: 'artists',
        display: 'Artists',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.LABEL],
            [USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 2,
        filters: [
            'type',
            'id',
            'name',
            'add_date',
            'enabled',
        ],
    },
    _charts: {
        id: 'charts',
        display: 'Charts',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 3,
        filters: [
            'type',
            'id',
            'name',
            'username',
            'user_id',
            'dj_name',
            'dj_slug',
            'genre_id',
            'add_date',
            'is_indexed',
            'enabled',
            'is_curated_playlist',
            'curated_playlist_genre_id',
        ],
    },
    _coupons: {
        id: 'coupons',
        display: 'Coupons',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.MARKETING],
        ],
        category: Config.API_CONTENT_OPS,
        route: Config.ROUTE_SUPPORT,
        fixedSearchColumns: 1,
        filters: [
            'type',
            'id',
            'code',
            'name',
            'discount_type',
            'start_date',
            'end_date',
        ],
    },
    _deliveries: {
        id: 'deliveries',
        display: 'Deliveries',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.LABELMGMT],
        ],
        category: Config.API_CONTENT_OPS,
        route: Config.ROUTE_DELIVERIES,
        fixedSearchColumns: 1,
        actions: true,
        filters: [
            'add_date',
            'album_action',
            'release_status',
            'status_message',
            'supplier',
            'label',
            'upc',
            'catalog_number',
            'release_title',
            'artist',
            'genre',
        ],
    },
    _dj_profiles: {
        id: 'dj_profiles',
        display: 'DJ Profiles',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.LABEL],
            [APP_SCOPES.CONTROL, USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CONTENT_OPS,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 2,
        filters: [
            'id',
            'name',
            'slug',
        ],
    },
    _playlists: {
        id: 'playlists',
        display: 'Playlists',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.LABEL],
            [APP_SCOPES.CONTROL, USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        actions: true,
        fixedSearchColumns: 2,
        filters: [
            'id',
            'name',
            'username',
            'user_id',
            'playlist_type_id',
            'genre_id',
            'created_date',
            'is_public',
            'playlist_status',
        ]
    },
    _genres: {
        id: 'genres',
        display: 'Genres',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.LABEL],
            [USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 2,
        filters: [
            'id',
            'name',
        ],
    },
    _hype_purchases: {
        id: 'hype_purchases',
        display: 'Hype Purchases',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.MARKETING],
        ],
        category: Config.API_ACCOUNTS,
        route: Config.ROUTE_SUPPORT,
        fixedSearchColumns: 2,
        filters: [
            'username',
            'email',
            'label_id',
            'label_name',
            'date',
            'voided',
        ],
    },
    _labels: {
        id: 'labels',
        display: 'Labels',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        actions: true,
        fixedSearchColumns: 3,
        filters: [
            'type',
            'id',
            'name',
            'supplier_id',
            'supplier_name',
            'label_manager',
            'default_pre_order_weeks',
            'is_available_for_pre_order',
            'enabled',
            'is_available_for_hype',
            'hype_eligible',
            'hype_active',
            'is_available_for_streaming',
        ],
    },
    _link_partners: {
        id: 'link_partners',
        display: 'Link! Partners',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CURATION],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        actions: true,
        fixedSearchColumns: 1,
        filters: [
            'id',
            'name',
        ],
    },
    _live_stream_host_vendors: {
        id: 'live_stream_host_vendors',
        display: 'Host Vendors',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CURATION],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_LIVE,
        fixedSearchColumns: 1,
        filters: []
    },
    _live_stream_tracklist_vendors: {
        id: 'live_stream_tracklist_vendors',
        display: 'Tracklist Vendors',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CURATION],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_LIVE,
        fixedSearchColumns: 1,
        filters: []
    },
    _live_streams: {
        id: 'live_streams',
        display: 'Live Streams',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CURATION],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_LIVE,
        fixedSearchColumns: 1,
        filters: [
            'id',
            'name',
            'enabled',
            'is_auto_enabled',
        ]
    },
    _artists_muzooka: {
        id: 'artists_muzooka',
        display: 'Muzooka',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.LABEL],
            [USER_SCOPES.SUPPLIER],
        ],
        category: Config.API_CATALOG,
        overrideEndpoint: 'artists/muzooka',
        fixedSearchColumns: 1,
        filters: [
            'name',
        ],
    },
    _orders: {
        id: 'orders',
        display: 'Orders',
        actions: true,
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.SUPPORT],
        ],
        category: Config.API_ACCOUNTS,
        route: Config.ROUTE_SUPPORT,
        fixedSearchColumns: 1,
        filters: [
            'id',
            'person_id',
            'username',
            'status',
            'purchaser_ip',
        ],
    },
    _page_modules: {
        id: 'page_modules',
        display: 'Page Modules',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
        ],
        category: Config.API_CURATION,
        route: Config.ROUTE_CURATION,
        fixedSearchColumns: 1,
        filters: [
            'type',
            'id',
            'name',
            'enabled',
            'type_id',
            'page_id',
            'page_type_id',
            'start_date',
            'end_date',
        ],
    },
    _pages: {
        id: 'pages',
        display: 'Pages',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
        ],
        category: Config.API_CURATION,
        route: Config.ROUTE_CURATION,
        fixedSearchColumns: 1,
        filters: [
            'type',
            'id',
            'name',
            'slug',
            'source_type_id',
            'page_type_id',
            'created_date',
            'start_date',
            'end_date',
            'enabled',
        ],
    },
    _releases: {
        id: 'releases',
        actions: true,
        display: 'Releases',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
            [USER_SCOPES.SUPPLIER],
            [USER_SCOPES.LABEL],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 3,
        filters: [
            'type',
            'id',
            'name',
            'artist_name',
            'artist_id',
            'label_name',
            'label_id',
            'supplier_name',
            'supplier_id',
            'label_manager',
            'track_name',
            'track_id',
            'catalog_number',
            'upc',
            'genre_id',
            'sub_genre_id',
            'is_hype',
            'release_type_id',
            'is_explicit',
            'exclusive',
            'enabled',
            'encoded_date',
            'publish_date',
            'new_release_date',
            'pre_order_date',
            'is_available_for_streaming',
        ],
    },
    _suppliers: {
        id: 'suppliers',
        display: 'Suppliers',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.LABELMGMT],
            [STAFF_SCOPES.MARKETING],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.FINANCE],
        ],
        category: Config.API_CATALOG,
        route: Config.ROUTE_CATALOG,
        fixedSearchColumns: 1,
        filters: [
            'type',
            'id',
            'name',
            'label_name',
            'label_id',
            'label_manager',
            'primary_contact_email',
            'primary_contact_username',
            'vendor_code',
            'date_created',
            'country_id',
            'multiple_contracts_active',
            'pposp_enrollment_status',
            'is_available_for_streaming',
        ],
    },
    _users: {
        id: 'users',
        display: 'Users',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.SUPPORT],
            [STAFF_SCOPES.CONTENTOPS],
            [STAFF_SCOPES.CURATION],
        ],
        category: Config.API_ACCOUNTS,
        route: Config.ROUTE_SUPPORT,
        actions: true,
        fixedSearchColumns: 2,
        filters: [
            'type',
            'id',
            'username',
            'email',
            'first_name',
            'last_name',
            'source_type_id',
            'dj_id',
            'dj_slug',
            'dj_is_indexed',
            'register_ip_address',
        ],
    },

    /**
     * Return all content types, does not filter by permissions
     */
    get all () {
        const _this = this;
        return Object.keys(_this)
            .map(type => type.replace('_', ''))
            .reduce((acc, key) => {
                acc.indexOf(key) < 0 && acc.push(key);
                return acc;
            }, [])
            .filter(type =>
                type !== 'all' &&
                type !== 'available' &&
                type !== 'catalog' &&
                type !== 'curation' &&
                type !== 'delivery' &&
                type !== 'djedit' &&
                type !== 'ugcremix' &&
                type !== 'live' &&
                type !== 'support' &&
                type !== 'type'
            );
    },

    /**
     * Return available content types, filtered by a users scope
     */
    get available () {
        const _this = this;
        return _this.all.filter(type => _this[type]);
    },

    /**
     * Return available catalog content types
     */
    get catalog () {
        const types = [
            'tracks',
            'releases',
            'artists',
            'charts',
            'dj_profiles',
            'suppliers',
            'labels',
            'playlists',
            'link_partners',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Return available curation content types
     */
    get curation () {
        const types = [
            'pages',
            'page_modules',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Return available DJ Edit content types
     */
    get djedit () {
        const types = [
            'tracks',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Return available DJ Remix content types
     */
    get ugcremix () {
        const types = [
            'tracks',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Return available support content types
     */
    get support () {
        const types = [
            'users',
            'coupons',
            'orders',
            'hype_offers',
            'hype_purchases',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Return available deliveries content types
     */
    get delivery () {
        const types = [
            'deliveries',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    get live () {
        const types = [
            'live_streams',
            'live_stream_host_vendors',
            'live_stream_tracklist_vendors',
        ];
        return types.filter(type => this.available.indexOf(type) !== -1);
    },

    /**
     * Get a specific content type by key, if the user is not authorized to view type, returns undefined.
     * @param {String} contentType key
     */
    type (contentType) {
        const _this = this;
        const type = { ..._this[contentType] };

        // if user cannot see this content type, return early
        if (!scopeCheck(_this[contentType].scopes)) return undefined;

        // if content type is available, filter fields based on filter scope
        type.filters = type.filters.filter(filter => scopeCheck(filters[filter].scopes || '*'));

        return type;
    },

    get tracks () {
        return this.type('_tracks');
    },
    get releases () {
        return this.type('_releases');
    },
    get suppliers () {
        return this.type('_suppliers');
    },
    get artists () {
        return this.type('_artists');
    },
    get dj_profiles () {
        return this.type('_dj_profiles');
    },
    get genres () {
        return this.type('_genres');
    },
    get labels () {
        return this.type('_labels');
    },
    get playlists () {
        return this.type('_playlists');
    },
    get charts () {
        return this.type('_charts');
    },
    get page_modules () {
        return this.type('_page_modules');
    },
    get pages () {
        return this.type('_pages');
    },
    get users () {
        return this.type('_users');
    },
    get coupons () {
        return this.type('_coupons');
    },
    get orders () {
        return this.type('_orders');
    },
    get hype_offers () {
        return this.type('_hype_offers');
    },
    get hype_purchases () {
        return this.type('_hype_purchases');
    },
    get deliveries () {
        return this.type('_deliveries');
    },
    get live_streams () {
        return this.type('_live_streams');
    },
    get live_stream_host_vendors () {
        return this.type('_live_stream_host_vendors');
    },
    get live_stream_tracklist_vendors () {
        return this.type('_live_stream_tracklist_vendors');
    },
    get link_partners () {
        return this.type('_link_partners');
    },

    get artists_muzooka () {
        return this.type('_artists_muzooka');
    }
};
