// PACAKGES
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// UTILS
import __ from 'localization';
import { captureException } from 'integrations/Sentry';
// UI
import { Button } from '@beatport-group/ui/Buttons';
import { Modal } from '@beatport-group/ui/Modal';

import './Confirm.scss';

const themes = {
    confirm: {
        category: ['option', 'primary'],
        label: [__('Cancel'), __('OK')],
    },
    delete: {
        category: ['option', 'warning'],
        label: [__('Cancel'), __('Delete')],
    },
    proceed: {
        category: ['option', 'primary'],
        label: [__('Cancel'), __('Proceed')],
    },
};

const removeConfirm = () => {
    const target = document.getElementById('confirm-dialog');
    ReactDOM.unmountComponentAtNode(target);
    target && target.remove();
};

export class ConfirmModal extends Component {
    static defaultProps = {
        theme: 'confirm',
        title: 'Confirmation Required',
    }

    state = {
        show: true,
    }

    componentDidMount () {
        this.container.getElementsByClassName('bp_confirm')[0].focus();
    }

    handleCancel = () => {
        this.props.callback && this.props.callback(false);
        this.setState({ show: false });
        removeConfirm();
    }

    handleConfirm = () => {
        this.props.resolve();
        this.props.callback && this.props.callback(true);
        this.setState({ show: false });
        removeConfirm();
    }

    renderModal () {
        const { message, theme, title } = this.props;
        return (
            <Modal
                className="confirm"
                open={this.state.show}
                onClose={this.handleCancel}
            >
                <h2>{title}</h2>
                <div>
                    {message}
                </div>
                <div className="confirm__button-container"
                    ref={buttonContainer => {
                        this.container = buttonContainer;
                    }}
                >
                    <Button
                        category={themes[theme].category[0]}
                        onClick={this.handleCancel}
                        className="confirm__button"
                        noMargin
                    >
                        {themes[theme].label[0]}
                    </Button>
                    <Button
                        data-testid="confirm"
                        category={themes[theme].category[1]}
                        onClick={this.handleConfirm}
                        className="confirm__button bp_confirm"
                        noMargin
                    >
                        {themes[theme].label[1]}
                    </Button>
                </div>
            </Modal>
        );
    }

    render () {
        return this.renderModal();
    }
}

const confirm = props => {
    const element = document.createElement('div');
    element.id = 'confirm-dialog';
    return new Promise((resolve, reject) => {
        try {
            ReactDOM.render(
                <ConfirmModal
                    reject={reject}
                    resolve={resolve}
                    {...props}
                />, document.body.appendChild(element)
            );
        }
        catch (err) {
            captureException(err);
            throw err;
        }
    });
};

export default confirm;
