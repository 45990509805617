// SETUP
import { searchCatalog } from 'integrations/BeatportAPI';
import { store } from 'store';
import { contentTypes } from 'types';
// UTILS
import __ from 'localization';
import {
    ACTIVE_BEATPORT_PAGE_MODULE_TYPES,
    ACTIVE_BEATSOURCE_PAGE_MODULE_TYPES,
    ACTIVE_SOURCE_TYPES,
    ALL_STAFF_SCOPES,
    APP_SCOPES,
    BRAND_SCOPES,
    MONTHS,
    STAFF_SCOPES,
} from 'utils/Constants';
import { convertHeadingName } from 'utils/Data';
import { getLabelManagerOptions, getModelOptions, getSubGenreOptions } from 'utils/Inputs';
// COMPONENTS
import { scopeCheck } from 'components/Permissions/Permissions';

const SELECT_PLACEHOLDER = __('Select...');

/**
 * Take a content type, return a new function that when called with a query,
 * fetches results from the Beatport API.
 *
 * @param {String} type
 */
const autocomplete = type => query => {
    if (!query) {return [];}
    return searchCatalog({
        type: type,
        q: query,
    }, true).then(results => results[type]);
};

const ACTIVE_PAGE_MODULE_TYPES = Config.APP_BRAND === BRAND_SCOPES.BEATPORT
    ? ACTIVE_BEATPORT_PAGE_MODULE_TYPES
    : ACTIVE_BEATSOURCE_PAGE_MODULE_TYPES;

export const filters = {
    type: {
        id: 'type',
        display: __('Type'),
        type: 'select',
        options: () => store.getState().search.types.map(type => ({
            value: contentTypes[type].id,
            display: contentTypes[type].display,
        })),
        getValueOnChange: (value) => {
            store.dispatch({ type: 'SEARCH_UPDATE_DISPLAY_TYPE', value });
            return { type: value };
        },
    },
    name: {
        id: 'name',
        display: () => convertHeadingName(store.getState().search.displayType),
        type: 'text',
    },
    title: {
        id: 'title',
        display: __('Title'),
        type: 'text',
    },
    mix_name: {
        id: 'mix_name',
        display: __('Mix Name'),
        type: 'text',
    },
    release: {
        id: 'release',
        display: __('Release'),
        type: 'text',
    },
    release_name: {
        id: 'release_name',
        display: __('Release Name'),
        type: 'text',
    },
    release_title: { // For Datastore searches
        id: 'release_title',
        display: __('Release Title'),
        type: 'text',
    },
    release_id: {
        id: 'release_id',
        display: __('Release ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    artist: {
        id: 'artist',
        display: __('Artist'),
        type: 'autocomplete',
        fetchFn: autocomplete('artists'),
        filterOnName: true,
    },
    artist_name: {
        id: 'artist_name',
        display: __('Artists'),
        type: 'text',
    },
    artist_id: {
        id: 'artist_id',
        display: __('Artist ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    muzooka_profile: {
        id: 'muzooka_profile',
        display: __('Muzooka ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    label: {
        id: 'label',
        display: __('Label'),
        type: 'text',
        scopes: ALL_STAFF_SCOPES,
    },
    label_name: {
        id: 'label_name',
        display: __('Labels'),
        type: 'text',
    },
    label_id: {
        id: 'label_id',
        display: __('Label ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    bpm: {
        id: 'bpm',
        display: __('BPM'),
        type: 'text',
    },
    key_name: {
        id: 'key_name',
        display: __('Key'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    id: {
        id: 'id',
        display: __('ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
        getValueOnChange: (value) => {
            return { id: value.replace(/\s+/g, ',') };
        },
    },
    username: {
        id: 'username',
        display: __('Username'),
        type: 'text',
    },
    user_id: {
        id: 'user_id',
        display: __('User ID'),
        type: 'text',
    },
    person_id: {
        id: 'person_id',
        display: __('User ID'),
        type: 'text',
    },
    dj_name: {
        id: 'dj_name',
        display: __('DJ Display Name'),
        type: 'text',
    },
    dj_slug: {
        id: 'dj_slug',
        display: __('DJ Slug'),
        type: 'text',
    },
    register_ip_address: {
        id: 'register_ip_address',
        display: __('Registration IP'),
        type: 'text',
    },
    purchaser_ip: {
        id: 'purchaser_ip',
        display: __('Order IP Address'),
        type: 'text',
    },
    supplier_name: {
        id: 'supplier_name',
        display: __('Suppliers'),
        type: 'text',
        scopes: ALL_STAFF_SCOPES,
    },
    supplier_id: {
        id: 'supplier_id',
        display: __('Supplier ID'),
        type: 'text',
        scopes: ALL_STAFF_SCOPES,
    },
    supplier: {
        id: 'supplier',
        display: __('Supplier'),
        type: 'text',
        scopes: ALL_STAFF_SCOPES,
    },
    primary_contact_email: {
        id: 'primary_contact_email',
        display: __('Primary Contact Email'),
        type: 'text',
    },
    primary_contact_username: {
        id: 'primary_contact_username',
        display: __('Primary Contact Username'),
        type: 'text',
    },
    vendor_code: {
        id: 'vendor_code',
        display: __('Vendor Code'),
        type: 'text',
    },
    isrc: {
        id: 'isrc',
        display: __('ISRC'),
        type: 'text',
    },
    upc: {
        id: 'upc',
        display: __('UPC'),
        type: 'text',
    },
    track_name: {
        id: 'track_name',
        display: __('Track Names'),
        type: 'text',
    },
    catalog_number: {
        id: 'catalog_number',
        display: __('Catalog Number'),
        type: 'text',
    },
    track_id: {
        id: 'track_id',
        display: __('Track ID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    guid: {
        id: 'guid',
        display: __('Track GUID'),
        type: 'text',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    date_range: {
        id: 'date_range',
        display: __('Date Range'),
        type: 'daterange',
    },
    add_date: {
        id: 'add_date',
        display: __('Date Added'),
        type: 'daterange',
    },
    encoded_date: {
        id: 'encoded_date',
        display: __('Date Encoded'),
        type: 'daterange',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    create_date: {
        id: 'create_date',
        display: __('Date Created'),
        type: 'daterange',
    },
    date_created: {
        id: 'date_created',
        display: __('Date Created'),
        type: 'daterange',
    },
    publish_date: {
        id: 'publish_date',
        display: __('Publish Date'),
        type: 'daterange',
    },
    exclusive_date: {
        id: 'exclusive_date',
        display: __('Exclusive Date'),
        type: 'daterange',
    },
    new_release_date: {
        id: 'new_release_date',
        display: __('New Release Date'),
        type: 'daterange',
    },
    release_date: {
        id: 'release_date',
        display: __('Release Date'),
        type: 'daterange',
    },
    free_download_start_date: {
        id: 'free_download_start_date',
        display: __('Free Download Start Date'),
        type: 'daterange',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    free_download_end_date: {
        id: 'free_download_end_date',
        display: __('Free Download End Date'),
        type: 'daterange',
        scopes: [[APP_SCOPES.CONTROL]],
    },
    pre_order_date: {
        id: 'pre_order_date',
        display: __('Pre Order Date'),
        type: 'daterange',
    },
    is_indexed: {
        id: 'is_indexed',
        display: __('Indexed'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Indexed') },
            { value: 'false', display: __('Not Indexed') },
        ],
    },
    enabled: {
        id: 'enabled',
        display: __('Enabled'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Enabled') },
            { value: 'false', display: __('Disabled') },
        ],
    },
    is_auto_enabled: {
        id: 'is_auto_enabled',
        display: __('Auto Enabled'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Enabled') },
            { value: 'false', display: __('Disabled') },
        ],
    },
    default_pre_order_weeks: {
        id: 'default_pre_order_weeks',
        display: __('Default Pre Order Weeks'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: '0', display: __('None') },
            { value: '1', display: __('One Week') },
            { value: '2', display: __('Two Weeks') },
            { value: '3', display: __('Three Weeks') },
            { value: '4', display: __('Four Weeks') },
        ],
    },
    is_available_for_pre_order: {
        id: 'is_available_for_pre_order',
        display: __('Is Available For Pre Order'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Available') },
            { value: 'false', display: __('Unavailable') },
        ],
    },
    is_explicit: {
        id: 'is_explicit',
        display: __('Is Explicit'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Is Explicit') },
            { value: 'false', display: __('Not Explicit') },
        ],
    },
    multiple_contracts_active: {
        id: 'multiple_contracts_active',
        display: __('Has Multiple Contracts Active'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Has Multiple contracts active') },
            { value: 'false', display: __('No contracts active') },
        ],
    },
    pposp_enrollment_status: {
        id: 'pposp_enrollment_status',
        display: __('PPOSP Enrollment Status'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: '1', display: __('Enrolled') },
            { value: '0', display: __('Not Enrolled') },
        ],
    },
    exclusive: {
        id: 'exclusive',
        display: __('Is Exclusive'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Is Exclusive') },
            { value: 'false', display: __('Not Exclusive') },
        ],
    },
    exclusive_period: {
        id: 'exclusive_period',
        display: __('Exclusive Period'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: '1', display: __('None') },
            { value: '2', display: __('4 Weeks') },
            { value: '3', display: __('8 Weeks') },
            { value: '4', display: __('2 Weeks') },
            { value: '0', display: __('Lifetime') },
        ],
    },
    month: {
        id: 'month',
        display: __('Month'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            ...MONTHS.map((month, i) => ({ value: i + 1, display: month })),
        ],
    },
    year: {
        id: 'year',
        display: 'Year',
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            ...(() => {
                const years = [];
                let current = (new Date()).getFullYear();

                while (current > 2003) {
                    const yearString = current.toString();
                    years.push({ value: yearString, display: yearString });
                    current--;
                }

                return years;
            })(),
        ],
    },
    store: {
        id: 'store',
        display: __('Store'),
        type: 'select',
        options: () => scopeCheck([[BRAND_SCOPES.BEATPORT]])
            ? [
                { value: 'music', display: __('Beatport Music') },
                { value: 'soundpack', display: __('Beatport Sounds') },
                { value: 'stem', display: __('Beatport Stems') },
                { value: 'cloud', display: __('Beatport Cloud') },
                { value: 'streaming', display: __('Beatport Link') },
            ] : [
                { value: 'music', display: __('Beatsource Music') },
                { value: 'streaming', display: __('Beatsource Streaming') },
            ]
        ,
    },
    genre: {
        id: 'genre',
        display: __('Genre'),
        type: 'text',
    },
    genre_id: {
        id: 'genre_id',
        display: __('Genre'),
        type: 'select',
        getValueOnChange: value => ({ genre_id: value, sub_genre_id: null }),
        options: () => getModelOptions('genres', 'genre'),
    },
    sub_genre: {
        id: 'sub_genre',
        display: __('Sub-genre'),
        type: 'text',
    },
    sub_genre_id: {
        id: 'sub_genre_id',
        display: __('Sub-genre'),
        type: 'select',
        options: getSubGenreOptions,
    },
    is_hype: {
        id: 'is_hype',
        display: __('Is Hype'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
        scopes: [[BRAND_SCOPES.BEATPORT]],
    },
    country_id: {
        id: 'country_id',
        display: __('Country'),
        type: 'select',
        options: () => getModelOptions('countries', 'country'),
    },
    current_status: {
        id: 'current_status',
        display: __('Current Status'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: () => getModelOptions('currentStatuses', 'currentStatus'),
    },
    encode_status: {
        id: 'encode_status',
        display: __('Encode Status'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'CONVERTED', display: __('CONVERTED') },
            { value: 'TRANSCODING', display: __('TRANSCODING') },
            { value: 'ERROR', display: __('ERROR') },
        ],
    },
    release_status: {
        id: 'release_status',
        display: __('Release Status'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: [
            'release-received',
            'metadata-processing-error',
            'filter-error',
            'filter-rejected',
            'ingest-error',
            'reingestion-error',
            'release-ingested',
            'encoding-error',
            'encoded',
            'currently-reingesting',
        ].map(key => ({ value: key, display: key })),
    },
    album_action: {
        id: 'album_action',
        display: __('Album Action'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: [
            'insert',
            'update',
            'delete',
            'purge',
        ].map(key => ({ value: key, display: key })),
    },
    status_message: {
        id: 'status_message',
        display: __('Status Message'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: [
            'Restricted genre',
            'Non existent genre',
            'Restricted label',
            'Non-existent label',
            'Source is disabled',
            'Supplier is disabled',
            'Restricted album action',
            'Filtering complete',
        ].map(key => ({ value: key, display: key })),
    },
    status: {
        id: 'status',
        display: __('Status'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'Open', display: __('Open') },
            { value: 'Complete', display: __('Complete') },
            { value: 'Deleted', display: __('Deleted') },
        ],
    },
    sale_type: {
        id: 'sale_type',
        display: __('Sale Type'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: () => getModelOptions('saleTypes', 'saleType'),
    },
    release_type: {
        id: 'release_type',
        display: __('Release Type'),
        type: 'text',
    },
    release_type_id: {
        id: 'release_type_id',
        display: __('Release Type'),
        type: 'select',
        options: () => getModelOptions('releaseTypes', 'releaseType'),
    },
    type_id: {
        id: 'type_id',
        display: __('Page Module Type'),
        type: 'select',
        options: () => getModelOptions('moduleTypes', 'moduleType')
            .filter(moduleType => ACTIVE_PAGE_MODULE_TYPES.includes(moduleType.display.toLowerCase()))
    },
    type_name: {
        id: 'type_name',
        display: __('Item Type'),
        type: 'text',
    },
    slug: {
        id: 'slug',
        display: __('Slug'),
        type: 'text',
    },
    page_id: {
        id: 'page_id',
        display: __('Page ID'),
        type: 'text',
    },
    page_type_id: {
        id: 'page_type_id',
        display: __('Page Type'),
        type: 'select',
        options: () => getModelOptions('pageTypes', 'pageType'),
    },
    page_type_name: {
        id: 'page_type_name',
        display: __('Page Type'),
        type: 'text',
    },
    source_type_id: {
        id: 'source_type_id',
        display: __('Source Type'),
        type: 'select',
        options: () => getModelOptions('sourceTypes', 'sourceType')
            .filter(sourceType => ACTIVE_SOURCE_TYPES.includes(sourceType.display.toLowerCase()))
    },
    source_type_name: {
        id: 'source_type_name',
        display: __('Source Type'),
        type: 'text',
    },
    start_date: {
        id: 'start_date',
        display: __('Enabled Start Date'),
        type: 'daterange',
    },
    end_date: {
        id: 'end_date',
        display: __('Enabled End Date'),
        type: 'daterange',
    },
    first_name: {
        id: 'first_name',
        display: __('First Name'),
        type: 'text',
    },
    last_name: {
        id: 'last_name',
        display: __('Last Name'),
        type: 'text',
    },
    dj_id: {
        id: 'dj_id',
        display: __('DJ Profile ID'),
        type: 'text',
    },
    dj_is_indexed: {
        id: 'dj_is_indexed',
        display: __('DJ Profile Indexed'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Indexed') },
            { value: 'false', display: __('Not Indexed') },
        ],
    },
    is_public: {
        id: 'is_public',
        display: __('Public'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Public') },
            { value: 'false', display: __('Private') },
        ],
    },
    playlist_status: {
        id: 'playlist_status',
        display: __('Published'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('Published') },
            { value: 'false', display: __('Not Published') },
        ],
    },
    playlist_type_id: {
        id: 'playlist_type_id',
        display: __('Playlist Type'),
        type: 'select',
        scopes: [[APP_SCOPES.CONTROL]],
        options: () => getModelOptions('playlistTypes', 'playlistType'),
    },
    is_curated_playlist: {
        id: 'is_curated_playlist',
        display: __('Is Curated Playlist'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    curated_playlist_genre_id: {
        id: 'curated_playlist_genre_id',
        display: __('Curated Playlist Genre'),
        type: 'select',
        options: () => getModelOptions('genres', 'genre'),
    },
    email: {
        id: 'email',
        display: __('Email'),
        type: 'text',
    },
    code: {
        id: 'code',
        display: __('Coupon Code'),
        type: 'text',
    },
    discount_amount: {
        id: 'discount_amount',
        display: __('Display Amount'),
        type: 'text',
    },
    discount_type: {
        id: 'discount_type',
        display: __('Discount Type'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'percentage', display: __('Percentage') },
        ],
    },
    created_date: {
        id: 'created_date',
        display: __('Created Date'),
        type: 'daterange',
    },
    label_manager: {
        id: 'label_manager',
        display: __('Label Manager'),
        type: 'select',
        scopes: [
            [STAFF_SCOPES.ADMIN],
            [STAFF_SCOPES.CURATION],
            [STAFF_SCOPES.MARKETING],
        ],
        options: () => getLabelManagerOptions(true, false),
    },
    is_available_for_hype: {
        id: 'is_available_for_hype',
        display: __('Available For Hype'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    hype_eligible: {
        id: 'hype_eligible',
        display: __('Hype Eligible'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    hype_active: {
        id: 'hype_active',
        display: __('Hype Active'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    is_available_for_streaming: {
        id: 'is_available_for_streaming',
        display: __('Available for Streaming'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    date: {
        id: 'date',
        display: __('Purchase Date'),
        type: 'daterange',
    },
    voided: {
        id: 'voided',
        display: __('Voided'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
    },
    dj_edits: {
        id: 'dj_edits',
        display: __('Is DJ Edit'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
        scopes: [[BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
            [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
            [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS]],
    },
    ugc_remixes: {
        id: 'ugc_remixes',
        display: __('Is UGC Remix'),
        type: 'select',
        options: [
            { value: '', display: SELECT_PLACEHOLDER },
            { value: 'true', display: __('True') },
            { value: 'false', display: __('False') },
        ],
        scopes: [[BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.ADMIN],
            [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CURATION],
            [BRAND_SCOPES.BEATSOURCE, APP_SCOPES.CONTROL, STAFF_SCOPES.CONTENTOPS]],
    }
};
