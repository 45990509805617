// PACKAGES
import csvjson from 'csvjson';
import isPlainObject from 'lodash/isPlainObject';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
// UTILS
import { MONTHS } from 'utils/Constants';
import __ from 'localization';

/**
 * take an object and remove null, empty, or undefined values.
 */
export const cleanObject = (object) => {
    return Object.keys(object)
        .filter((i) => object[i] !== null && object[i] !== '' && typeof object[i] !== 'undefined')
        .reduce((obj, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
};

export const getYearMonthDay = date => {
    if (!date) {
        return null;
    }

    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
};

export const getMonthDayYear = date => {
    if (!date) {
        return null;
    }

    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${month}/${day}/${year}`;
};

export const formatDateString = value => {
    const formatted = value.trim();
    // if input matches YYYY-MM-DD or YYYY-M-D, replace hyphens
    // to avoid date contructor weirdness
    return formatted.match(/^\d{4}-\d{1,2}-\d{1,2}$/)
        ? formatted.replace(/-/g, '/')
        : formatted;
};

export const formatDateIsoString = value => {
    const date = typeof value === 'object' ? value : new Date(value);
    return date.toISOString().trim();
};

export const friendlyDate = (value) => {
    if (!value) return 'No date provided';

    const dateTokens = (new Date(value.replace(' ', 'T'))).toISOString().split('T');
    const timeTokens = dateTokens[1].split(':');
    const hoursInt = parseInt(timeTokens[0], 10);
    const hours = ((hoursInt + 11) % 12 + 1);
    const suffix = hoursInt < 12 ? 'am' : 'pm';
    return `${dateTokens[0]}, ${hours}:${timeTokens[1]}${suffix}`;
};

/**
 * Take a Date in the form YYYY-MM-DD and converts it to Long Month Day, Year.
 *
 * For example, 2010-03-04 becomes June 3, 2010
 *
 * @param {Date} date 1
 */
export const dateLongMonth = date => {
    const month = MONTHS[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
};

/**
 * Take a String in the form YYYY-MM-DD:YYYY-MM-DD or YYYY-MM-DD:
 *
 *
 * @param {String} dateRange 1
 */
export const friendlyDateRange = dateRange => {
    const dates = dateRange.split(':').filter(x => x.length !== 0).map(date => dateLongMonth(new Date(date)));
    return dates.length === 1 ? ' Since ' + dates[0] : ' From ' + dates[0] + ' To ' + dates[1];
};

/**
 * take an API respone from Beatport API and return something we can use.
 */
export const parseMetadata = (response) => {
    return {
        page: Number(response.page.split('/')[0]),
        totalPages: Number(response.page.split('/')[1]),
        previous: Boolean(response.previous),
        next: Boolean(response.next),
    };
};

/**
 * Take two Strings and determines if they are considered different search queries.
 *
 * For example, "hello" and "hello " are considered inequal, but as search terms they are equal.
 *
 * @param {String} string 1
 * @param {String} string 2
 */
export const diffQueryString = (string1, string2) => {
    const processString = (s) => String(s).toLowerCase().trim();
    return processString(string1) !== processString(string2);
};

export const properCase = (string) => string[0].toUpperCase() + string.substr(1);

export const properNode = (string) => string.split('_').map(properCase).join(' ');

export const copyToClipboard = (text) => {
    // See if we can use fancy new browser APIs
    // This will only work once we are serving this app over https
    // See https://developers.google.com/web/updates/2018/03/clipboardapi#security_and_permissions
    if (navigator.clipboard && document.location.protocol.includes('https')) {
        return navigator.clipboard && navigator.clipboard.writeText(text);
    }
    else {
    // Fallback to the oldschool method
        return new Promise((resolve, reject) => {
            let textarea;
            try {
                textarea = document.createElement('textarea');
                textarea.value = text;
                textarea.style.position = 'fixed';
                textarea.style.bottom = 0;
                textarea.style.right = 0;
                textarea.visibility = 'hidden';
                document.body.appendChild(textarea);
                textarea.focus();
                textarea.select();
                const success = document.execCommand('copy');
                if (success) {
                    resolve(text);
                }
                else {
                    throw new Error('Failed to copy to the clipboard');
                }
            }
            catch (e) {
                reject(e);
            }
            finally {
                document.body.removeChild(textarea);
            }
        });
    }
};

export const randomNumber = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
};

/**
 * Takes two values, casts them to strings if they are numbers, and then evaluates their equality
 * @param {*} a - The first value to compare
 * @param {*} b - The second value to compare
 * @returns {boolean} If arguments are equal, after converting number values to strings
 */
export const stringCompare = (a, b) => {
    const aValue = typeof a === 'number' ? a.toString() : a;
    const bValue = typeof b === 'number' ? b.toString() : b;
    return aValue === bValue;
};

export const prefixFlattenObject = (obj, prefix = '') => Object.keys(obj).reduce((acc, key) => {
    const current = obj[key];
    const currentPrefix = `${prefix}${key}`;

    isPlainObject(current)
        ? acc = { ...acc, ...prefixFlattenObject(current, currentPrefix + '_') } // eslint-disable-line no-param-reassign
        : acc[currentPrefix] = current;

    return acc;
}, {});

/**
 * Takes two objects of filterValues, and returns the keys that differ between them.
 */
export const diffFlatObject = (value, other) => {
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    return [
        ...valueKeys.filter(key => otherKeys.indexOf(key) === -1 || value[key] !== other[key]),
        ...otherKeys.filter(key => valueKeys.indexOf(key) === -1),
    ];
};

/**
 * Gets filters from the query params of a url.
 * Optionally excludes type from the returned value.
 */
export const getFiltersFromURL = (url, excludeType = true) => {
    const urlTokens = url.split('?');
    if (urlTokens.length < 2) {
        return {};
    }

    const params = qs.parse(urlTokens[urlTokens.length - 1]);
    excludeType && delete params.type;

    return params;
};

/**
 * @description splitCamelCase takes a string and splits camel case values into proper case titles
 * @param {String} string
 */
export const splitCamelCase = (string) => {
    return string
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([a-z])/g, ' $1$2')
        .replace(/ +/g, ' ')
        .replace(/^./, (str) => str.toUpperCase())
        .split(' ').filter(i => i).join(' ');
};

/**
 * @description Takes a BP-API url and extracts the ID from it
 * @param {String} url
 */
export const getIdFromURL = (url) => {
    return url ? url.split('/').filter(idx => idx).pop() : null;
};

/**
 * @description Puts dollar signs and thousands comma separators on a single value
 * @param {String} value to format (e.g. "12345.00" => "$12,345.00")
 */
export const formatSaleAmount = value => {
    if (!value) {
        return '';
    }

    let prefix = '$';
    let amount = value;

    value[0] === '-' && (prefix = '-$') && (amount = value.slice(1));

    const tokens = amount.split('.');

    if (tokens[0].length < 3) {
        return `${prefix}${amount}`;
    }
    let counter = 1;
    tokens[0] = tokens[0].split('').reduceRight((acc, el) => {
        const thousands = counter % 3 === 0 && counter !== 1 && counter !== tokens[0].length;
        thousands ? (acc = `,${el}${acc}`) : (acc = el + acc); // eslint-disable-line no-param-reassign
        counter++;
        return acc;
    }, '');

    return `${prefix}${tokens.join('.')}`;
};

/**
 * @description Puts dollar signs in front of sales values
 * @param {Object} sales summary
 * @param {Array} Optional. keys is a list of specific keys in the object that should be formatted
 */
export const formatSaleValues = (data, keys = null) => {
    const keysToFormat = keys || Object.keys(data);

    const formatted = keysToFormat.reduce((acc, key) => {
        const value = data[key];

        !value && (acc[key] = '$0.00');

        if (typeof value === 'string') {
            acc[key] = formatSaleAmount(value);
        }

        return acc;
    }, {});

    return {
        ...data,
        ...formatted,
    };
};

export const responsiveDrawerProps = mqId => mqId < 2
    ? { offset: 0, size: '100%' }
    : { offset: '6rem' };

/**
 * Check if an array (superset) contains all values of another array (subset)
 *
 * @param {Array} superset
 * @param {Array} subset
 * @returns {Boolean}
 */
export const arrayContainsAll = (superset, subset) => {
    return subset.every((value) => {
        return (superset.indexOf(value) >= 0);
    });
};

/**
 * Check if an array (superset) contains at least one value from another array (subset)
 *
 * @param {Array} superset
 * @param {Array} subset
 * @returns {Boolean}
 */
export const arrayContainsOne = (superset, subset) => {
    return subset.some((value) => {
        return (superset.indexOf(value) >= 0);
    });
};

/**
 * A function to get all pages from a list route, and resolve the amalgamated results
 *
 * @param {*} fetcher The integration function to fetch a page in the list, this function should only take a payload
 * @param {*} payload An optional payload to pass to the fetcher
 * @returns {Promise} A promise that resolves with all results from all pages in an Array
 */
export const getAllPagesInList = (fetcher, payload = {}) => fetcher(payload).then(first => {
    const pages = parseInt(first.page.split('/')[1], 10);
    const pageRequests = [];

    // Start at 2 because first page is alreday fetched
    for (let page = 2; page <= pages; page++) {
        pageRequests.push(fetcher({ ...payload, page }));
    }

    return Promise.all(pageRequests).then(data =>
        [first, ...data].reduce((acc, { results }) => [
            ...acc,
            ...results,
        ], [])
    );
});

export const convertHeadingName = (contentType) => {
    if (contentType) {
        const contentTypeBaseName = contentType.lastIndexOf('ies') === contentType.length - 3
            ? contentType.replace('ies', 'y')
            : contentType.slice(0, -1);
        return contentTypeBaseName.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ') + ' Name';
    }
    return 'Name';
};

export const convertSaleTypeName = saleType => {
    if (saleType) {
        const saleTypeBaseName = saleType.endsWith('s') ? saleType.slice(0, -1) : saleType;
        return saleTypeBaseName.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ') + ' Name';
    }
    return 'Name';
};

export const formatFileSize = sizeInBytes => {
    const sizes = ['Bytes', 'KB', 'MB'];
    if (sizeInBytes === 0) return '';
    const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)), 10);
    return Math.round(sizeInBytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const statusMessageMapping = data => {
    const messageMap = {
        'Non-existent label': `Label='${data.label}' does not exist or is not mapped for supplier='${data.supplier}'`,
        'Restricted genre': `Genre '${data.genre}' does not allow ingestion for supplier='${data.supplier}`,
        'Non existent genre': `Genre '${data.genre}' is not mapped for supplier='${data.supplier}`,
        'invalid-image': 'Missing or out of aspect image file',
        'already-live-error': `Cannot insert. Release '${data.release_title}' already live or pending for label '${data.label}'`,
        'reingestion-error': `Cannot process. Release '${data.release_title}' was updated in the last 90 seconds`,
        fatal: 'Release has no label or label was normalized and contained no normal characters',
        'Restricted label': `Label '${data.label}' is blacklisted in the label mapping table for supplier='${data.supplier}`,
        'missing-isrc': 'Track missing ISRC on insert',
    };
    return messageMap[data.status_message] || '';
};

export const getDeliveryStatusDescription = data => {
    const updatedMsg = 'updated metadata successfully';
    switch (data.release_status) {
        case 'index-error':
            return `${data.xml_file_path} did not validate against xml`;
        case 'updated':
            return updatedMsg;
        case 'published':
            return data.album_action === 'update' ? updatedMsg : 'published';
        case 'ingestion-error':
        case 'move-error':
        case 'filter-bypassed':
        case 'filter-rejected':
            return statusMessageMapping(data);
        default:
            return '';
    }
};

export const isWandSelectable = data => {
    return (!data.ingested && data.release_status !== 'currently-reingesting');
};

/**
 * Function to determine if arrays are different
 *
 * @param {Array} origArray  - original array
 * @param {Array} newArray  - new array
 *
 * @returns {Boolean}
 */
export const isDifferentArray = (origArray, newArray) => origArray.length !== newArray.length ||
  !isEmpty(differenceWith(origArray, newArray, isEqual));

/**
 * Function to reorder an array of tracks
 *
 * _used by Charts and Releases_
 *
 * @param {Array} source
 * @param {{id: Number, position: Number}} update
 */
export const reorder = (source, update = null) => {
    const dest = new Array(source.length);
    let tempSource = source;
    let newIndex = null;

    if (update !== null) {
        const { position, id } = update;
        const entry = source.find(value => value.id === id);
        newIndex = position - 1;
        tempSource = source.filter(value => value !== entry);
        dest[newIndex] = { ...entry, track_position: { id, position } };
    }

    for (let index = 0; index < dest.length; index++) {
        if (newIndex !== index) {
            const element = tempSource.shift();
            dest[index] = { ...element, track_position: { id: element.id, position: index + 1 } };
        }
    }
    return dest;
};

/**
 * Function to get default values of fields
 *
 * _used by Page
 *
 * @param {Array} fields
 * @returns {Object}
 */
export const getDefaultItemValues = (fields) => (
    fields
        .reduce((acc, field) => {
            const { defaultValue, id } = field;
            if (defaultValue !== undefined) acc[id] = defaultValue;
            return acc;
        }, {})
);

/**
 * Function to find model option in array of options
 *
 * _used by Page
 *
 * @param {String} searchKey
 * @param {String} searchValue
 * @param {Array} options
 */
export const findModelOptionValue = (searchKey, searchValue, options) => (
    options.find(el => el[searchKey] === searchValue)?.value
);

/**
 * Function to return a list of user ids from a csv file
 *
 * _used by Coupon
 *
 * @param {HTML File Upload} files
 */
export const parseUserIdsFromCSV = files => {
    const Notifier = window.Beatport.Notifier;

    try {
        const csv = files[0].result.target.result;
        const json = csvjson.toColumnArray(csv);

        if (!json.user_id && !json.person_id) {
            Notifier.create(__('A column heading of `person_id` or `user_id` is required'), 'danger');
            return {};
        }

        return { person_ids: json.person_id || json.user_id };
    }
    catch (e) {
        console.warn(e);
        Notifier.create(__('There was a problem uploading your CSV file.'), 'danger');
    }
};

/**
 * Function to format a payload value to a correct price (eliminate non-int/float vals)
 *
 * _used by Coupon
 *
 * @param {Object} data
 * @param {String} key
 */
export const priceToPayload = (data, key) => {
    const priceVal = `${data[key]}`;
    const priceWithDecimalExp = /\d*(\.\d+)?$/g;
    return { [key]: parseFloat(priceVal.match(priceWithDecimalExp)[0]) };
};

/**
 * Function to sort selected items by how they were inputted into an ID filter
 * This allows catalog items to be in a user-defined order when creating Charts or Page Modules
 *
 * _used by Charts and PageModule
 *
 * @param {Array} selected
 * @param {String} idOrder
 */
export const sortByFilterId = (selected, idOrder) => {
    if (idOrder) {
        const orderArray = idOrder.split(',').map(Number);
        const orderMap = orderArray.reduce((map, id, index) => {
            map[id] = index;
            return map;
        }, {});

        return selected.sort((a, b) => {
            return orderMap[a.id] - orderMap[b.id];
        });
    }

    else {
        return selected;
    }
};
