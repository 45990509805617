// PACKAGES
import React from 'react';
import { Provider } from 'react-redux';
import { Link, Router } from 'react-router-dom';
// UI
import UserInterface from '@beatport-group/ui/config';
// SETUP
import { store, history } from './store';

// IE & Edge do not support Promise.finally()
import promiseFinally from 'promise.prototype.finally';
promiseFinally.shim();

// Configure bp-ui to use custom routes for item types.
UserInterface.useCustomAnchors = true;
UserInterface.Anchor = ({ href, ...extra }) => <Link to={href} {...extra} />;
['artists', 'charts', 'labels', 'tracks', 'releases', 'suppliers']
    .forEach((itemType) => {
        UserInterface.url(itemType, `${Config.ROUTE_CATALOG}/${itemType}/:id`);
    });

class Bootstrap extends React.Component {
    constructor (props) {
        super(props);

        this.mediaQueries = [];

        this.addMediaQueryListeners = this.addMediaQueryListeners.bind(this);
        this.removeMediaQueryListeners = this.removeMediaQueryListeners.bind(this);
    }

    componentDidMount () {
        this.addMediaQueryListeners();
    }

    componentWillUnmount () {
        this.removeMediaQueryListeners();
    }

    addMediaQueryListeners () {
        Config.MEDIA_QUERIES.forEach((mq) => {
            const mql = window.matchMedia(mq.mediaQuery);
            const mqListener = (e) => {
                if (e.matches) {
                    store.dispatch({ type: 'MEDIA_QUERY_UPDATE', mq: { id: mq.id, name: mq.name } });
                }
            };

            if (mql.matches) {
                store.dispatch({ type: 'MEDIA_QUERY_UPDATE', mq: { id: mq.id, name: mq.name } });
            }
            mql.addListener(mqListener);

            this.mediaQueries.push({ mql, mqListener });
        });
    }

    removeMediaQueryListeners () {
        this.mediaQueries.forEach(mediaQuery => {
            mediaQuery.mql.removeListener(mediaQuery.mqListener);
        });

        this.mediaQueries = [];
    }

    render () {
        return (
            <Provider store={store}>
                <Router history={history}>
                    {this.props.children}
                </Router>
            </Provider>
        );
    }
}

export default Bootstrap;
