// PACKAGES
import qs from 'qs';
// SETUP
import { ServerError } from 'errors';
import { history } from 'store';

const getSortIndex = (a, key) => a === key ? -1 : 1;

export const sortPayload = (a, b) => {
    if (a === 'type' || b === 'type') {
        return getSortIndex(a, 'type');
    }

    if (a === 'page' || b === 'page') {
        return getSortIndex(a, 'page');
    }

    if (a === 'per_page' || b === 'per_page') {
        return getSortIndex(a, 'per_page');
    }

    return a.localeCompare(b);
};

const Rest = {
    handleAPIResponse (response) {
        if (
            !response.ok ||
      (response.status >= 400 && response.status < 600)
        ) {
            const defaultMsg = `Received unexpected status code: ${response.status}`;
            const errMsg = response.statusText ? response.statusText : defaultMsg;
            throw new ServerError(errMsg, response.status, response);
        }
        return response;
    },

    handleBodyResponse (response) {
        if (
            !response.ok ||
      (response.status >= 400 && response.status < 600)
        ) {
            return response.json().then(err => {
                const message = err.detail ? err.detail : err;
                throw JSON.stringify(message);
            });
        }
        else {
            return response;
        }
    },

    handleAPIError (error) {
        switch (error.status) {
            case 401:
                history.push(`${Config.ROUTE_LOGOUT}`);
                break;
            default:
                throw error;
        }
    },

    parseResponse (response) {
        switch (response.headers.get('content-type').toLowerCase()) {
            case 'application/json':
                return response.json();
            default:
                return response;
        }
    },

    get (endpoint, payload = {}, auth = null, headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }) {
        const queryString = qs.stringify(payload, { sort: sortPayload });
        const endpointConcat = queryString.length
            ? `${endpoint}?${queryString}`
            : endpoint;

        return fetch(endpointConcat, {
            headers: {
                ...headers,
                Authorization: auth,
            },
            method: 'GET',
            redirect: 'follow',
            credentials: 'same-origin',
        })
            .then(this.handleAPIResponse)
            .then(this.parseResponse)
            .catch(this.handleAPIError);
    },

    post (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'POST',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleAPIResponse)
            .then(response => response.json())
            .catch(this.handleAPIError);
    },

    postWithStatus (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'POST',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleAPIResponse)
            .catch(this.handleAPIError);
    },

    put (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'PUT',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleAPIResponse)
            .then(response => response.json())
            .catch(this.handleAPIError);
    },

    putWithStatus (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'PUT',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleBodyResponse)
            .catch(this.handleAPIError);
    },

    patch (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'PATCH',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleAPIResponse)
            .then(response => response.json())
            .catch(this.handleAPIError);
    },

    delete (endpoint, payload = {}, auth = null) {
        return fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: auth,
            },
            method: 'DELETE',
            redirect: 'follow',
            credentials: 'same-origin',
            body: JSON.stringify(payload),
        })
            .then(this.handleAPIResponse)
            .then(response => response)
            .catch(this.handleAPIError);
    },
};

export { Rest };
