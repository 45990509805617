// PACKAGES
import React, { Component } from 'react';
import {
    Route,
    Switch,
    withRouter,
} from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
// UTILS
import __ from 'localization';
import { APP_SCOPES, USER_SCOPES } from 'utils/Constants';
// COMPONENTS
import AsyncComponent from 'components/AsyncComponent';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Permissions from 'components/Permissions/Permissions';

const Routes = {
    auth: {
        pathname: Config.ROUTE_AUTH_LOGIN,
        component: () => import('./routes/auth/Login'),
        scopes: '*',
    },
    auth_verify: {
        pathname: Config.ROUTE_AUTH_VERIFY,
        component: () => import('./routes/auth/Verify'),
        scopes: '*',
    },
    logout: {
        pathname: Config.ROUTE_LOGOUT,
        component: () => import('./routes/auth/Logout'),
        scopes: '*',
    },
    service_agreement: {
        pathname: Config.ROUTE_SERVICE_AGREEMENT,
        component: () => import('./routes/auth/ServiceAgreement'),
        scopes: [
            [APP_SCOPES.BASEWARE, USER_SCOPES.SUPPLIER],
        ],
    },
    app: {
        pathname: '**',
        component: () => import('./App'),
        scopes: [
            [APP_SCOPES.CONTROL],
            [APP_SCOPES.BASEWARE],
        ],
    },
};

/**
 * Top level application router. Dispatches requests (public and private) to their proper components
 */
class Router extends Component {
    constructor (props) {
        super(props);
        this.props.initAuth();
    }

    render () {
        const { history } = this.props;
        const error = __('Authorization failed. Please try again.');
        return (
            <Switch>
                {Object.keys(Routes).map((route) => (
                    <Route
                        exact
                        key={route}
                        path={Routes[route].pathname}
                        render={props => (
                            <ErrorBoundary>
                                <Permissions
                                    allowedScopes={Routes[route].scopes}
                                    callback={(auth) => {
                                        history.push({
                                            pathname: Config.ROUTE_AUTH_LOGIN,
                                            state: {
                                                error: auth
                                                    && auth.scope
                                                    /* istanbul ignore next */ && error
                                            },
                                        });
                                    }}
                                >
                                    <AsyncComponent
                                        {...props.match.params}
                                        resolve={Routes[route].component}
                                        history={history}
                                    />
                                </Permissions>
                            </ErrorBoundary>
                        )}
                    />
                ))}
            </Switch>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAuth: () => dispatch({ type: 'INIT_AUTH' }),
    };
};

export default compose(
    withRouter,
    connect(null, mapDispatchToProps),
)(Router);
