// UTILS
import { createModels } from 'models/createModels';

const defaultState = Object.keys(createModels).reduce((acc, key) => {
    acc[key] = createModels[key]();
    return acc;
}, {});

const models = (state = defaultState, action) => {
    switch (action.type) {
        case 'CREATE_API_MODEL':
            return {
                ...state,
                [action.key]: action.model,
            };
        default:
            return state;
    }
};

export { models };
