// PACKAGES
import { normalize, schema } from 'normalizr';
// UTILS
import {
    fetchAccountTypes,
    fetchAudioFormats,
    fetchBillingInfoTypes,
    fetchCommercialModels,
    fetchContactTypes,
    fetchContractTypes,
    fetchCountries,
    fetchCurrencies,
    fetchCurrentStatuses,
    fetchDiscountTypes,
    fetchExclusivePeriods,
    fetchGenres,
    fetchHostVendors,
    fetchImageTypes,
    fetchItemTypes,
    fetchKeys,
    fetchLabelManagers,
    fetchPageModuleTypes,
    fetchPageTypes,
    fetchReleaseTypes,
    fetchReasons,
    fetchSaleTypes,
    fetchSourceTypes,
    fetchSongwriterTypes,
    fetchStates,
    fetchTracklistVendors,
    fetchPlaylistTypes,
} from 'integrations/BeatportAPI';

// Arguments to be passed to create/init models
// id: { id, fetchFn, primary entity, nested entities }
export const models = {
    accountTypes: ['accountTypes', fetchAccountTypes, 'accountType'],
    audioFormats: ['audioFormats', fetchAudioFormats, 'audioFormat'],
    billingInfoTypes: ['billingInfoTypes', fetchBillingInfoTypes, 'billingInfoType'],
    commercialModels: ['commercialModels', fetchCommercialModels, 'commercialModel'],
    countries: ['countries', fetchCountries, 'country'],
    contactTypes: ['contactTypes', fetchContactTypes, 'contactType'],
    contractTypes: ['contractTypes', fetchContractTypes, 'contractType'],
    currencies: ['currencies', fetchCurrencies, 'currency'],
    currentStatuses: ['currentStatuses', fetchCurrentStatuses, 'currentStatus'],
    discountTypes: ['discountTypes', fetchDiscountTypes, 'discountType'],
    exclusivePeriods: ['exclusivePeriods', fetchExclusivePeriods, 'exclusivePeriod'],
    genres: ['genres', fetchGenres, 'genre', { sub_genres: 'sub_genre' }],
    hostVendors: ['hostVendors', fetchHostVendors, 'hostVendor'],
    imageTypes: ['imageTypes', fetchImageTypes, 'imageType'],
    itemTypes: ['itemTypes', fetchItemTypes, 'itemType'],
    keys: ['keys', fetchKeys, 'key'],
    labelManagers: ['labelManagers', fetchLabelManagers, 'labelManager'],
    moduleTypes: ['moduleTypes', fetchPageModuleTypes, 'moduleType'],
    pageTypes: ['pageTypes', fetchPageTypes, 'pageType'],
    playlistTypes: ['playlistTypes', fetchPlaylistTypes, 'playlistType'],
    releaseTypes: ['releaseTypes', fetchReleaseTypes, 'releaseType'],
    reasons: ['reasons', fetchReasons, 'reason'],
    saleTypes: ['saleTypes', fetchSaleTypes, 'saleType'],
    songwriterTypes: ['songwriterTypes', fetchSongwriterTypes, 'songwriterTypes'],
    sourceTypes: ['sourceTypes', fetchSourceTypes, 'sourceType'],
    states: ['states', fetchStates, 'state'],
    tracklistVendors: ['tracklistVendors', fetchTracklistVendors, 'tracklistVendor'],
};

const createModel = (id, fetchFn, entity, nestedEntities) => data => {
    if (!data) {
        return {
            entities: {
                [entity]: {},
                ...Object.keys(nestedEntities || {}).reduce((acc, key) => {
                    acc[nestedEntities[key]] = {};
                    return acc;
                }, {}),
            },
            result: [],
        };
    }

    if (!nestedEntities) {
        return normalize(data.results, [ new schema.Entity(entity) ]);
    }

    const nestedSchemas = Object.keys(nestedEntities).reduce((acc, key) => {
        acc[key] = [ new schema.Entity(nestedEntities[key]) ];
        return acc;
    }, {});

    return normalize(data.results, [ new schema.Entity(entity, nestedSchemas) ]);
};

export const createModels = Object.keys(models).reduce((acc, key) => {
    acc[key] = createModel(...models[key]);
    return acc;
}, {});
