// PACKAGES
import React, { Component } from 'react';
import { connect } from 'react-redux';
// UTILS
import { store } from 'store';

export const scopeCheck = (allowedScopeGroups = []) => {
    if (allowedScopeGroups === '*') {
        return true;
    }

    const auth = store.getState().auth;
    const allPermissions = [
        ...auth.scope.split(' '),
        ...auth.feature,
        Config.APP_BRAND,
    ];

    return !!allowedScopeGroups.find(allowedScopes =>
        allowedScopes.every(allowedScope => allPermissions.indexOf(allowedScope) !== -1)
    );
};

const Default = ({ children }) => children || null;
Default.displayName = 'Default';

class Permissions extends Component {
    static Default = Default;

    render () {
        const {
            allowedScopes,
            auth,
            callback,
            children,
        } = this.props;

        const authorized = scopeCheck(allowedScopes);

        if (authorized) {
            return React.Children.toArray(children).filter(
                child => child.type.displayName !== 'Default'
            );
        }

        callback && callback(auth);

        let fallback = React.Children.toArray(children).filter(
            child => child.type.displayName === 'Default'
        );

        return fallback || null;
    }
}
Permissions.defaultProps = {
    auth: {},
    allowedScopes: [],
    callback: null,
};

export default connect(({ auth }) => ({ auth }))(Permissions);

export const withPermissions = (
    allowedScopes,
    callback,
    DefaultComponent = () => null,
) => WrappedComponent => {
    return connect(({ auth }) => ({ auth }))(props => {
        return (
            <Permissions
                allowedScopes={allowedScopes}
                auth={props.auth}
                callback={callback}
            >
                <WrappedComponent {...props} />
                {Default && (
                    <Permissions.Default>
                        <DefaultComponent />
                    </Permissions.Default>
                )}
            </Permissions>
        );
    });
};
