const navigation = (state = { open: false }, action) => {
    switch (action.type) {
        case 'NAVIGATION_TOGGLE_DRAWER':
            if (typeof action.open !== 'undefined') {
                return { open: action.open };
            }
            return { open: !state.open };

        default:
            return state;
    }
};

export { navigation };
