// UTILS
import { captureException } from 'integrations/Sentry';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    }
    catch (e) {
        /* istanbul ignore next */
        captureException(e);
        /* istanbul ignore next */
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify({
            drawer: state.drawer,
            auth: state.auth,
            preferences: state.preferences,
        });
        localStorage.setItem('state', serializedState);
    }
    catch (e) {
        /* istanbul ignore next */
        captureException(e);
    }
};
