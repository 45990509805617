// PACKAGES
import { createStore, combineReducers, compose } from 'redux';
import qs from 'qs';
// SETUP
import { loadState, saveState } from './localStorage';
import { createBrowserHistory, createMemoryHistory } from 'history';
import reducers from './reducers';
// COMPONENTS
import userConfirm from 'components/Confirm';

const persistedState = loadState();
const history = process.env.NODE_ENV === 'test'
    ? createMemoryHistory({
        getUserConfirmation: null,
    })
    : createBrowserHistory({
        getUserConfirmation: async (message, callback) => {
            try {
                await userConfirm({ message, theme: 'confirm', callback });
            }
            catch (error) {
                console.error(error);
            }
        },
    });

history.params = (param = null) => {
    const obj = qs.parse(history.location.search.replace('?', ''));
    if (param) {
        return obj[param];
    }

    return obj;
};

const store = createStore(
    combineReducers({
        ...reducers,
    }),
    persistedState,
    compose(
        ...[
            /* istanbul ignore next */
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        ].filter(i => i !== undefined)
    ),
);

store.subscribe(() => {
    saveState(store.getState());
});

export { store, history };
